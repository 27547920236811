import { Adset, CreateAdset, UpdateAdset } from 'models/dsp/adset.dto'
import {
  BannerQueryResult,
  VideoQueryResult,
  NativeQueryResult,
  CreativeSearchResult,
  BannerSearchResult,
  VideoSearchResult,
  NativeSearchResult,
  PaginatedSearchResult,
} from 'models/dsp/creative-search-result.dto'
import { ICreativeService } from './icreative-service'
import { ConnectedService } from 'services/connected-service'
import {
  ConnectedServiceQueryResult,
  ConnectedServiceResult,
} from 'services/connected-service-result'
import { APIQuery } from 'models/query/query.dto'
import ExistingListComponent from 'pages/dsp/campaign-wizard/components/targeting-rules/target-attributes/ExistingListComponent'
import { Banner } from 'models/dsp/banner.dto'
import moment from 'moment'

type BannerSearchResultDto = CreativeSearchResult & { imageUrl: string }
type BannerQueryResultDto = ConnectedServiceQueryResult<BannerSearchResultDto>

type VideoSearchResultDto = CreativeSearchResult & { url: string; thumbnail?: string | undefined }
type VideoQueryResultDto = ConnectedServiceQueryResult<VideoSearchResultDto>

type NativeSearchResultDto = CreativeSearchResult & { assets: { image?: { imageUrl: string } } }
type NativeQueryResultDto = ConnectedServiceQueryResult<NativeSearchResultDto>

type ConnectedAdSet = ConnectedServiceResult<AdSetDto>
type ConnectedAdSets = ConnectedServiceResult<AdSetDto[]>

interface BannerStatsResults extends ConnectedServiceResult<any> {}

interface AdSetResults extends ConnectedServiceResult<Adset[]> {}
interface AdSetDto {
  id: string
  sourceId: string
  accountId?: string
  name: string
  banners: { id: number; name: string }[]
  bannerVideos: { id: number; name: string }[]
  bannerNatives: { id: number; name: string }[]
}

export class ConnectedCreativeService extends ConnectedService implements ICreativeService {
  private cache: { time: number; data: any } = { time: 0, data: null }

  async queryBanners(
    query: string | undefined,
    page: number,
    pageSize: number,
    accountId?: string,
    sourceId?: string,
  ): Promise<BannerQueryResult> {
    let filters: any[] = []
    if (query) {
      filters.push({ name: { like: `%${query}%` } })
    }
    if (accountId) {
      filters.push({ accountId })
    }
    if (sourceId) {
      filters.push({ sourceId })
    }
    const results = await this._post<BannerQueryResultDto>('dsp/banners/query', {
      type: 'Banners',
      filters: filters,
      pagination: {
        number: page,
        size: pageSize,
      },
      includeRowCount: true,
    })

    return {
      banners:
        results.data?.map((banner) =>
          this.mapCreativeSearchResultDtoToSearchResult<BannerSearchResultDto, BannerSearchResult>(
            banner,
            (banner) => ({ url: banner.imageUrl }),
          ),
        ) || [],
      total: results.metadata?.count || 0,
      page: page,
    }
  }

  async queryVideos(
    query: string | undefined,
    page: number,
    pageSize: number,
    accountId?: string,
    sourceId?: string,
  ): Promise<VideoQueryResult> {
    let filters: any[] = []
    if (query) {
      filters.push({ name: { like: `%${query}%` } })
    }
    if (accountId) {
      filters.push({ accountId })
    }
    if (sourceId) {
      filters.push({ sourceId })
    }
    const results = await this._post<VideoQueryResultDto>('dsp/videos/query', {
      type: 'BannerVideos',
      filters,
      pagination: {
        number: page,
        size: pageSize,
      },
      includeRowCount: true,
    })

    return {
      videos:
        results.data?.map(
          (video) =>
            this.mapCreativeSearchResultDtoToSearchResult<VideoSearchResultDto, VideoSearchResult>(
              video,
              (video) => ({ url: '' }),
            ), // todo: add thumbnail
        ) || [],
      total: results.metadata?.count || 0,
      page: page,
    }
  }

  async queryNatives(
    query: string | undefined,
    page: number,
    pageSize: number,
    accountId?: string,
    sourceId?: string,
  ): Promise<NativeQueryResult> {
    let filters: any[] = []
    if (query) {
      filters.push({ name: { like: `%${query}%` } })
    }
    if (accountId) {
      filters.push({ accountId })
    }
    if (sourceId) {
      filters.push({ sourceId })
    }

    const results = await this._post<NativeQueryResultDto>('dsp/natives/query', {
      type: 'BannerNatives',
      filters,
      pagination: {
        number: page,
        size: pageSize,
      },
      includeRowCount: true,
    })
    return {
      natives:
        results.data?.map((native) =>
          this.mapCreativeSearchResultDtoToSearchResult<NativeSearchResultDto, NativeSearchResult>(
            native,
            (native) => ({ image: native.assets?.image?.imageUrl }),
          ),
        ) || [],
      total: results.metadata?.count || 0,
      page: page,
    }
  }

  async queryAdsets(_analyticFilter: any[], query: APIQuery): Promise<Adset[]> {
    const response = await this._post<AdSetResults>('dsp/adSets/query', query)
    if (response.data !== undefined) {
      return response.data
    }
    throw new Error(response.message)
  }

  async getAdSetById(adsetId: string): Promise<Adset | null> {
    const results = await this._get<ConnectedAdSet>(`dsp/adSets/${adsetId}`)
    if (results?.data === undefined) {
      return null
    }
    return this.mapAdsetDtoToAdset(results.data)
  }

  async createAdset(adset: CreateAdset): Promise<Adset> {
    const result = await this._post<AdSetDto>('dsp/adSets', adset)
    return this.mapAdsetDtoToAdset(result)
  }

  async updateAdset(adset: UpdateAdset): Promise<Adset> {
    const result = await this._put<ConnectedAdSet>(`dsp/adSets`, adset)
    return this.mapAdsetDtoToAdset(result.data!)
  }

  async getCreativeStats() {
    let now = Date.now()
    if (now - this.cache.time < 5000) {
      return this.cache.data
    } else {
      const results = await this._post<BannerQueryResultDto>('analytics/query', {
        model: 'CreativeStatus',
        measures: [],
        dimensions: [
          'VisitationStats.banner',
          'CreativeStatus.placements',
          'CreativeStatus.countActive',
          'CreativeStatus.countOffline',
          'CreativeStatus.status',
        ],
      })

      this.cache.time = Date.now()
      this.cache.data = results.data
      return results.data
    }
  }

  async queryBannersWithStats(_analyticFilter: any[], query: APIQuery): Promise<any[]> {
    let creativeStats = await this.getCreativeStats()
    let _creativeStats = creativeStats
    if (!creativeStats) {
      console.error('BannerStats is not available')
      return []
    }

    if (_analyticFilter.length !== 0) {
      query = { ...query, pagination: undefined }
    }

    try {
      const response = await this._post<BannerStatsResults>('dsp/banners/query', query)
      if (response.success !== true || response.data === undefined) {
        console.error('Banners is not available')
        return []
      }

      if (_analyticFilter.length !== 0) {
        _creativeStats = this.filterAnalyticData(creativeStats, _analyticFilter)
        console.log('filteredAnalyticData', _creativeStats)
        return this.mapBannersWithStats(true, response.data, _creativeStats)
      } else {
        return this.mapBannersWithStats(false, response.data, _creativeStats)
      }
    } catch (e) {
      console.error('BannerStatus is not available')
      return []
    }
  }
  async queryVideosWithStats(_analyticFilter: any[], query: APIQuery): Promise<any[]> {
    let creativeStats = await this.getCreativeStats()
    let _creativeStats = creativeStats
    if (!creativeStats) {
      console.error('BannerStats is not available')
      return []
    }

    if (_analyticFilter.length !== 0) {
      query = { ...query, pagination: undefined }
    }

    try {
      const response = await this._post<BannerStatsResults>('dsp/videos/query', query)
      if (response.success !== true || response.data === undefined) {
        console.error('Videos is not available')
        return []
      }

      if (_analyticFilter.length !== 0) {
        _creativeStats = this.filterAnalyticData(creativeStats, _analyticFilter)
        console.log('filteredAnalyticData', _creativeStats)
        return this.mapVideosWithStats(true, response.data, _creativeStats)
      } else {
        return this.mapVideosWithStats(false, response.data, creativeStats)
      }
    } catch (e) {
      console.error('BannerStatus is not available')
      return []
    }
  }

  async queryNativesWithStats(_analyticFilter: any[], query: APIQuery): Promise<any[]> {
    let creativeStats = await this.getCreativeStats()
    let _creativeStats = creativeStats
    if (!creativeStats) {
      console.error('BannerStats is not available')
      return []
    }
    if (_analyticFilter.length !== 0) {
      query = { ...query, pagination: undefined }
    }
    try {
      const response = await this._post<BannerStatsResults>('dsp/natives/query', query)
      if (response.success !== true || response.data === undefined) {
        console.error('Natives is not available')
        return []
      }
      if (_analyticFilter.length !== 0) {
        _creativeStats = this.filterAnalyticData(creativeStats, _analyticFilter)
        console.log('filteredAnalyticData', _creativeStats)
        return this.mapNativesWithStats(true, response.data, _creativeStats)
      } else {
        return this.mapNativesWithStats(false, response.data, creativeStats)
      }
    } catch (e) {
      console.error('BannerStatus is not available')
      return []
    }
  }

  async getBannerById(id: string): Promise<BannerSearchResult> {
    const result = await this._get<ConnectedServiceResult<BannerSearchResultDto>>(
      `dsp/banners/${id}`,
    )
    return this.mapCreativeSearchResultDtoToSearchResult<BannerSearchResultDto, BannerSearchResult>(
      result.data!,
      (banner) => ({ url: banner.imageUrl }),
    )
  }

  async getVideoById(id: string): Promise<VideoSearchResult> {
    const result = await this._get<ConnectedServiceResult<VideoSearchResultDto>>(`dsp/videos/${id}`)
    return this.mapCreativeSearchResultDtoToSearchResult<VideoSearchResultDto, VideoSearchResult>(
      result.data!,
      (video) => ({ url: '' }), // todo: add thumbnail
    )
  }

  async getNativeById(id: string): Promise<NativeSearchResult> {
    const result = await this._get<ConnectedServiceResult<NativeSearchResultDto>>(
      `dsp/natives/${id}`,
    )
    return this.mapCreativeSearchResultDtoToSearchResult<NativeSearchResultDto, NativeSearchResult>(
      result.data!,
      (native) => ({ image: native.assets?.image?.imageUrl }),
    )
  }

  async getAdSets(query: string | undefined, page?: number, limit?: number): Promise<Adset[]> {
    try {
      const results = await this._get<ConnectedAdSets>(
        'dsp/adSets?page=' + page + '&limit=' + limit + (query ? '&query=' + query : ''),
      )
      if (results.success) {
        return (results.data ?? []).map(this.mapAdsetDtoToAdset)
      }
      throw new Error(results.message)
    } catch (e) {
      return []
    }
  }

  private mapAdsetDtoToAdset(adset: AdSetDto): any {
    return {
      id: adset.id,
      accountId: adset.accountId,
      sourceId: adset.sourceId,
      name: adset.name,
      banners: adset.banners,
      videos: adset.bannerVideos,
      natives: adset.bannerNatives,
    }
  }

  private mapVideosWithStats(inclusive: boolean, videos: any[], statusData: any[]) {
    let tableData: any[] = []
    if (videos && videos.length !== 0) {
      let statusDataObj: any = {}
      if (statusData && statusData.length) {
        statusData.forEach((v) => {
          const currKey = v['CreativeStatus.banner']
          const currVal = {
            countActive: v['CreativeStatus.countActive'],
            countOffline: v['CreativeStatus.countOffline'],
            placements: v['CreativeStatus.placements'],
            status: v['CreativeStatus.status'],
          }
          statusDataObj[currKey] = currVal
        })
      }

      videos.sort(function (a, b) {
        return moment.utc(b.updated_at).diff(moment.utc(a.updated_at))
      })
      videos.forEach((video) => {
        const currId = video.id
        if (inclusive) {
          if (statusDataObj[currId]) {
            tableData.push({
              ...video,
              vast_video_width: video.vastVideoWidth,
              vast_video_height: video.vastVideoHeight,
              bid_ecpm: video.bidEcpm,
              total_cost: video.totalCost,
              total_budget: video.totalBudget,
              countActive: (statusDataObj[currId] && statusDataObj[currId].countActive) || '0',
              countOffline: (statusDataObj[currId] && statusDataObj[currId].countOffline) || '0',
              placements: (statusDataObj[currId] && statusDataObj[currId].placements) || '0',
              status: statusDataObj[currId] && statusDataObj[currId].status,
              videoName: video.name && video.name,
              interval:
                video.interval_start &&
                video.interval_end &&
                moment(video.interval_start).format('YYYY/MM/DD') +
                  ' - ' +
                  moment(video.interval_end).format('YYYY/MM/DD'),
            })
          }
        } else {
          tableData.push({
            ...video,
            vast_video_width: video.vastVideoWidth,
            vast_video_height: video.vastVideoHeight,
            bid_ecpm: video.bidEcpm,
            total_cost: video.totalCost,
            total_budget: video.totalBudget,
            countActive: (statusDataObj[currId] && statusDataObj[currId].countActive) || '0',
            countOffline: (statusDataObj[currId] && statusDataObj[currId].countOffline) || '0',
            placements: (statusDataObj[currId] && statusDataObj[currId].placements) || '0',
            status: statusDataObj[currId] && statusDataObj[currId].status,
            videoName: video.name && video.name,
            interval:
              video.interval_start &&
              video.interval_end &&
              moment(video.interval_start).format('YYYY/MM/DD') +
                ' - ' +
                moment(video.interval_end).format('YYYY/MM/DD'),
          })
        }
      })
    }

    return tableData
  }

  private mapNativesWithStats(inclusive: boolean, natives: any[], statusData: any[]) {
    let tableData: any[] = []
    if (natives && natives.length !== 0) {
      let statusDataObj: any = {}
      if (statusData && statusData.length) {
        statusData.forEach((v) => {
          const currKey = v['CreativeStatus.banner']
          const currVal = {
            countActive: v['CreativeStatus.countActive'],
            countOffline: v['CreativeStatus.countOffline'],
            placements: v['CreativeStatus.placements'],
            status: v['CreativeStatus.status'],
          }
          statusDataObj[currKey] = currVal
        })
      }

      natives.sort(function (a, b) {
        return moment.utc(b.updated_at).diff(moment.utc(a.updated_at))
      })
      natives.forEach((native) => {
        const currId = native.id
        if (inclusive) {
          if (statusDataObj[currId]) {
            tableData.push({
              ...native,
              interval_start: native.intervalStart,
              interval_end: native.intervalEnd,
              bid_ecpm: native.bidEcpm,
              total_cost: native.totalCost,
              total_budget: native.totalBudget,
              countActive: (statusDataObj[currId] && statusDataObj[currId].countActive) || '0',
              countOffline: (statusDataObj[currId] && statusDataObj[currId].countOffline) || '0',
              placements: (statusDataObj[currId] && statusDataObj[currId].placements) || '0',
              status: statusDataObj[currId] && statusDataObj[currId].status,
              interval:
                native.interval_start &&
                native.interval_end &&
                moment(native.interval_start).format('YYYY/MM/DD') +
                  ' - ' +
                  moment(native.interval_end).format('YYYY/MM/DD'),
            })
          }
        } else {
          tableData.push({
            ...native,
            interval_start: native.intervalStart,
            interval_end: native.intervalEnd,
            bid_ecpm: native.bidEcpm,
            total_cost: native.totalCost,
            total_budget: native.totalBudget,
            countActive: (statusDataObj[currId] && statusDataObj[currId].countActive) || '0',
            countOffline: (statusDataObj[currId] && statusDataObj[currId].countOffline) || '0',
            placements: (statusDataObj[currId] && statusDataObj[currId].placements) || '0',
            status: statusDataObj[currId] && statusDataObj[currId].status,
            interval:
              native.interval_start &&
              native.interval_end &&
              moment(native.interval_start).format('YYYY/MM/DD') +
                ' - ' +
                moment(native.interval_end).format('YYYY/MM/DD'),
          })
        }
      })
    }

    return tableData
  }

  private mapBannersWithStats(inclusive: boolean, banners: any[], statusData: any[]) {
    let tableData: any[] = []
    if (banners && banners.length !== 0) {
      let statusDataObj: any = {}
      if (statusData && statusData.length) {
        statusData.forEach((v) => {
          const currKey = v['CreativeStatus.banner']
          const currVal = {
            countActive: v['CreativeStatus.countActive'],
            countOffline: v['CreativeStatus.countOffline'],
            placements: v['CreativeStatus.placements'],
            status: v['CreativeStatus.status'],
          }
          statusDataObj[currKey] = currVal
        })
      }

      banners.forEach((banner) => {
        const { campaignStats = {} } = banner
        const currId = banner.id
        if (inclusive) {
          if (statusDataObj[currId]) {
            tableData.push({
              ...banner,
              total_budget: banner.totalBudget,
              bid_ecpm: banner.bidEcpm,
              total_cost: banner.totalCost,
              interval_start: banner.intervalStart,
              interval_end: banner.intervalEnd,
              contenttype: banner.contentType,
              imageurl: banner.imgUrl,
              attributes: banner.attr,
              countActive: (statusDataObj[currId] && statusDataObj[currId].countActive) || '0',
              countOffline: (statusDataObj[currId] && statusDataObj[currId].countOffline) || '0',
              placements: (statusDataObj[currId] && statusDataObj[currId].placements) || '0',
              status: statusDataObj[currId] && statusDataObj[currId].status,
              clicks: (campaignStats.analytics && campaignStats.analytics.clicks) || 0,
              clickthroughUrl: banner.meta ? this.getClickthroughUrl(banner.meta) : undefined,
              contractedImpression:
                (campaignStats.analytics && campaignStats.analytics.contractedImpression) || '',
              daysInFlight: (campaignStats.analytics && campaignStats.analytics.daysInFlight) || '',
              engagementRate:
                (campaignStats.analytics && campaignStats.analytics.engagementRate) || '',
              impressions: (campaignStats.analytics && campaignStats.analytics.impressions) || '',
              mediaSpend: (campaignStats.analytics && campaignStats.analytics.mediaSpend) || '',
              type: 'banner',
              interval:
                banner.interval_start &&
                banner.interval_end &&
                moment(banner.interval_start).format('YYYY/MM/DD') +
                  ' - ' +
                  moment(banner.interval_end).format('YYYY/MM/DD'),
            })
          }
        } else {
          tableData.push({
            ...banner,
            total_budget: banner.totalBudget,
            bid_ecpm: banner.bidEcpm,
            total_cost: banner.totalCost,
            interval_start: banner.intervalStart,
            interval_end: banner.intervalEnd,
            contenttype: banner.contentType,
            imageurl: banner.imgUrl,
            attributes: banner.attr,
            countActive: (statusDataObj[currId] && statusDataObj[currId].countActive) || '0',
            countOffline: (statusDataObj[currId] && statusDataObj[currId].countOffline) || '0',
            placements: (statusDataObj[currId] && statusDataObj[currId].placements) || '0',
            status: statusDataObj[currId] && statusDataObj[currId].status,
            clicks: (campaignStats.analytics && campaignStats.analytics.clicks) || 0,
            clickthroughUrl: banner.meta ? this.getClickthroughUrl(banner.meta) : undefined,
            contractedImpression:
              (campaignStats.analytics && campaignStats.analytics.contractedImpression) || '',
            daysInFlight: (campaignStats.analytics && campaignStats.analytics.daysInFlight) || '',
            engagementRate:
              (campaignStats.analytics && campaignStats.analytics.engagementRate) || '',
            impressions: (campaignStats.analytics && campaignStats.analytics.impressions) || '',
            mediaSpend: (campaignStats.analytics && campaignStats.analytics.mediaSpend) || '',
            type: 'banner',
            interval:
              banner.interval_start &&
              banner.interval_end &&
              moment(banner.interval_start).format('YYYY/MM/DD') +
                ' - ' +
                moment(banner.interval_end).format('YYYY/MM/DD'),
          })
        }
      })
    }
    console.log('tableData', tableData)
    return tableData
  }

  private getClickthroughUrl(obj: any) {
    return obj['clickThroughUrl']
  }

  private filterAnalyticData(data: any[], conditions: any[]) {
    return data.filter((item) => {
      // Check all conditions
      return conditions.every((condition) => {
        let value = item[`CreativeStatus.${condition.colId}`]

        // Handle type conversion for number comparisons
        if (condition.filterType === 'number') {
          value = +value
        }

        switch (condition.type) {
          case 'equals':
            return value === condition.filter
          // Add more cases for other comparison types (e.g., "greaterThan", "lessThan")
          case 'contains':
            return value.toLowerCase().includes(condition.filter.toLowerCase())

          default:
            return false
        }
      })
    })
  }

  private mapCreativeSearchResultDtoToSearchResult<
    U extends CreativeSearchResult,
    T extends CreativeSearchResult,
  >(creativeSearchResult: CreativeSearchResult, extraFields: (u: U) => Partial<T>): T {
    return {
      id: creativeSearchResult.id,
      name: creativeSearchResult.name,
      createdAt: creativeSearchResult.createdAt,
      startDate: creativeSearchResult.startDate,
      endDate: creativeSearchResult.endDate,
      sourceId: creativeSearchResult.sourceId,
      ...extraFields(creativeSearchResult as U),
    } as T // Typescript doesn't know if Partial<T> is enough to make T, so we
    // need to cast it and just pinkey swear that it's true
  }
}
