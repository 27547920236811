export enum BudgetPeriod {
  HOURLY = "Hourly",
  DAILY = "Daily",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
  TOTAL = "Total"
}

export enum BudgetType {
  IMPRESSIONS = "Impressions",
  DOLLARS = "Dollars"
}

export interface BudgetItem {
  period: BudgetPeriod;
  type: BudgetType;
  amount: number;
};

export interface BudgetItemDto {
  period: number;
  type: number;
  amount: number;
}

export interface TotalBudgetItem {
  type: BudgetType;
  amount: number;
}

