import { IPlacementService } from "./iplacement-service";

export class MockPlacementService implements IPlacementService {
  createPlacement(placement: any): void {
  }

  updatePlacement(placement: any): void {
  }

  getPlacementById(id: string): any {
  }

  getPlacements(): any {
  }

  deletePlacement(id: string): void {
  }
}