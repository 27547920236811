/*eslint-disable */

import OverviewIcon from '../components/Icons/overviewIcon'
import CreateIcon from '../components/Icons/createIcon'
import SourcesIcon from '../components/Icons/sourcesIcon'
import AudiencesIcon from '../components/Icons/audiencesIcon'
import DestinationsIcon from '../components/Icons/destinationsIcon'
import InspectorIcon from '../components/Icons/inspectorIcon'
import ActivateIcon from '../components/Icons/activateIcon'
import InventoryIcon from '../components/Icons/marketplacesIcon'
import SettingsIcon from '../components/Icons/settingsIcon'
import SupportIcon from '../components/Icons/supportIcon'
// import ComplianceIcon from '../components/Icons/complianceIcon'
import ReportIcon from '../components/Icons/reportIcon'

const menuData = [
  {
    title: 'Home',
    roles: ['admin', 'client'],
    key: 'SurfsideHome',
    url: '/home',
    icon: 'icmn icmn-home',
    $id: '/menu/home',
    staticIcon: OverviewIcon,
  },
  {
    title: 'Create',
    key: 'createNew',
    roles: ['admin', 'client'],
    icon: 'icmn icmn-plus',
    $id: '/menu/create',
    staticIcon: CreateIcon,
    children: [
      {
        title: 'Campaign',
        key: 'createCampaignNew',
        url: '/dsp/create-campaign',
        $id: '/menu/create/campaign',
        $name: 'Campaign',
      },
      {
        title: 'Source',
        key: 'createSource',
        url: '/create/source',
        $id: '/menu/create/source',
        $name: 'Source',
      },
      {
        key: 'createAudience',
        title: 'Audience',
        url: '/create/audience',
        $id: '/menu/create/audience',
        $name: 'Audience',
      },
      {
        key: 'createDestination',
        title: 'Destination',
        url: '/create/destination',
        $id: '/menu/create/destination',
        $name: 'Destination',
      },
    ],
  },
  {
    title: 'Sources',
    roles: ['admin', 'client'],
    key: 'viewAllSource',
    icon: 'icmn icmn-enter utils__spin-delayed--pseudo-selector',
    url: '/all/sources',
    $id: '/menu/all/sources',
    staticIcon: SourcesIcon,
  },
  {
    title: 'Audiences',
    roles: ['admin'],
    key: 'viewAllAudience',
    icon: 'icmn icmn-users utils__spin-delayed--pseudo-selector',
    url: '/all/audiences',
    $id: '/menu/all/audiences',
    staticIcon: AudiencesIcon,
  },
  {
    title: 'Destinations',
    roles: ['admin'],
    key: 'viewAllDestinations',
    icon: 'icmn icmn-exit utils__spin-delayed--pseudo-selector',
    url: '/all/destinations',
    $id: '/menu/all/destinations',
    staticIcon: DestinationsIcon,
  },
  {
    title: 'Places',
    key: 'viewPlacees',
    $name: 'Places',
    $id: '/menu/places',
    staticIcon: InspectorIcon,
    children: [
      {
        key: 'viewPointsOfInterest',
        title: 'Points of Interest',
        url: '/all/poi',
        $id: '/menu/all/poi',
        $name: 'Points of Interest',
      },
      {
        key: 'viewRetailInsights',
        title: 'Retail Insights',
        url: '/all/retail',
        $id: '/menu/all/retail',
        $name: 'Retail Insights',
      }
    ],
  },
  {
    title: 'Ads Manager',
    roles: ['admin'],
    key: 'viewAdLauncher',
    icon: 'icmn icmn-binoculars',
    $name: 'AdLauncher',
    $id: '/menu/dsp',
    staticIcon: ActivateIcon,
    children: [
      {
        key: 'viewDSPCampaigns',
        title: 'Campaigns',
        url: '/dsp/campaigns',
        $id: '/menu/dsp/campaigns',
        $name: 'Campaigns',
      },
      {
        key: 'viewDSPStrategies',
        title: 'Strategies',
        $skip: true,
        url: '/dsp/strategies',
        $id: '/menu/dsp/strategies',
        $name: 'Strategies',
      },
      {
        key: 'viewDSPPlacements',
        title: 'Placements',
        url: '/dsp/placements',
        $id: '/menu/dsp/placements',
        $name: 'Placements',
      },
      {
        key: 'viewDSPCreative',
        title: 'Creative',
        url: '/dsp/creative',
        $id: '/menu/dsp/creative',
        $name: 'Creative',
      },
      {
        key: 'viewDSPAdSets',
        title: 'Ad Sets',
        url: '/dsp/adSets',
        $id: '/menu/dsp/adsets',
        $name: 'AdSets',
      },
      {
        key: 'viewDSPTargeting',
        title: 'Targeting',
        url: '/dsp/targeting',
        $id: '/menu/dsp/targeting',
        $name: 'Targeting',
      },
      {
        key: 'viewDSPRulesets',
        title: 'Rulesets',
        url: '/dsp/rulesets',
        $id: '/menu/dsp/rulesets',
        $name: 'Rulesets',
      },
      {
        key: 'viewDSPLists',
        title: 'Lists',
        url: '/dsp/lists',
        $id: '/menu/dsp/lists',
        $name: 'Lists',
      }
    ],
  },
  {
    title: 'Inventory',
    // $skip: true,
    key: 'inventory',
    icon: 'icmn icmn-enter utils__spin-delayed--pseudo-selector',
    url: '/marketplace',
    $id: '/menu/inventory',
    staticIcon: InventoryIcon,
    children: [
      {
        key: 'channels',
        title: 'Channels',
        url: '/inventory/channels',
        $id: '/menu/inventory/channels',
      },
      {
        key: 'sites',
        title: 'Sites',
        url: '/inventory/sites',
        $id: '/menu/inventory/sites',
      },
      {
        key: 'zones',
        title: 'Zones',
        url: '/inventory/zones',
        $id: '/menu/inventory/zones',
      },
      {
        // $skip: true,
        key: 'adtypes',
        title: 'Ad Types',
        url: '/inventory/adtypes',
        $id: '/menu/inventory/adtypes',
      },
      {
        // $skip: true,
        key: 'products',
        title: 'Products',
        url: '/inventory/products',
        $id: '/menu/inventory/products',
      }
    ],
  },
  {
    title: 'Reporting',
    key: 'viewReports',
    icon: 'icmn icmn-enter utils__spin-delayed--pseudo-selector',
    url: '/reports',
    $id: '/menu/reports',
    $name: 'Reports',
    staticIcon: ReportIcon,
  },
  {
    $skip: true,
    divider: true,
  },
  {
    title: 'Documentation',
    key: 'Documentation',
    $skip: true,
    $id: '/menu/documentation',
    staticIcon: SupportIcon,
    children: [
      {
        $skip: true,
        key: 'Api',
        title: 'User Guide',
        url: 'https://docs.surfside.io',
        target: '_blank',
        $id: '/menu/docs/userguide',
      },
      // {
      //   $skip: false,
      //   key: 'Api',
      //   title: 'API Docs',
      //   url: '/docs/api',
      //   $id: '/menu/docs/api',
      // }
    ],
  },
  {
    title: 'Settings',
    key: 'settings',
    $id: '/menu/settings',
    staticIcon: SettingsIcon,
    children: [
      {
        $skip: true,
        key: 'account',
        title: 'Account',
        url: '/account',
        $id: '/menu/settings/account',
        children: [
          {
            // $skip: true,
            key: 'Plans & Billing',
            title: 'Plans & Billing',
            url: '/account/overview',
            $id: '/menu/settings/account/plans&billing',
            $name: 'Settings',
            $context: '/main-menu'
          },
          {
            // $skip: true,
            key: 'apiKeys',
            title: 'API Keys',
            url: '/admin/apikeys',
            $id: '/menu/settings/account/apikeys',
            $name: 'Settings',
            $context: '/main-menu'
          },
          {
            // $skip: true,
            key: 'credentials',
            title: 'Credentials',
            url: '/credentials',
            $id: '/menu/settings/account/credentials',
            $name: 'Settings',
            $context: '/main-menu'
          },
          {
            // $skip: true,
            key: 'advertisers',
            title: 'Advertisers',
            url: '/advertisers',
            $id: '/menu/settings/account/advertisers',
            $name: 'Settings',
            $context: '/main-menu'}
        ]
      },
      {
        key: 'Tags',
        title: 'Tags',
        url: '/tags',
        $name: 'Tags',
        $id: '/component/tags',
        $context: '/component'
        // $skip: true
      },
      {
        key: 'Location',
        title: 'Location Lists',
        $id: '/menu/location',
        $name: 'Location',
        children: [
          {
            key: 'Attribution',
            title: 'Attribution',
            url: '/attribution',
            $id: '/menu/location/attribution',
            $name: 'Attribution',
          },
          {
            key: 'Points of Interest',
            title: 'Points of Interest',
            url: '/location/poi',
            $name: 'PointsOfInterest',
            $id: '/menu/location/poi',
          },
        ],
      },
      {
        key: 'System',
        title: 'System',
        $id: '/menu/settings/system',
        $name: 'System',
        children: [
          {
            key: 'User Management',
            title: 'Permissions',
            //url: '/users'
            $name: 'UserManagement',
            $id: '/menu/usermanagement',
            children: [
              {
                key: 'Account Management',
                title: 'Accounts',
                url: '/accounts',
                $name: 'AccountManagement',
                $id: '/menu/accounts',
              },
              {
                key: 'users',
                title: 'Users',
                url: '/usermanagement/users',
                $id: '/menu/usermanagement/users',
              },
              {
                key: 'roles',
                title: 'Roles',
                url: '/usermanagement/roles',
                $id: '/menu/usermanagement/roles',
              },
              {
                key: 'features',
                title: 'Features',
                url: '/usermanagement/features',
                $id: '/menu/usermanagement/features',
              },
              {
                key: 'privileges',
                title: 'Privileges',
                url: '/usermanagement/privileges',
                $id: '/menu/usermanagement/privileges',
              },
              {
                key: 'quotas',
                title: 'Quotas',
                url: '/usermanagement/quotas',
                $id: '/menu/usermanagement/quotas',
              },
              {
                key: 'apirules',
                title: 'API Rules',
                url: '/usermanagement/api-rules',
                $id: '/menu/usermanagement/api-rules',
                $name: 'APIRules',
              },

              {
                key: 'billing',
                title: 'Billing',
                url: '/usermanagement/billing',
               $id: '/menu/usermanagement/billing',
               $name: 'Billing',
              },
            ],
          },
          {
            key: 'Metrics',
            title: 'Metrics',
            url: '/metrics',
            $name: 'Metrics',
            $id: '/menu/metrics',
            children: [
              {
                key: 'Usage',
                title: 'Usage',
                url: '/metrics/usage',
                $id: '/menu/metrics/usage',
                $name: 'Usage'
              },
              {
                key: 'Bidder',
                title: 'Bidder',
                url: '/metrics/bidder',
                $id: '/menu/metrics/bidder',
                $name: 'Bidder'
              },
              {
                key: 'Reasons',
                title: 'Reasons',
                url: '/metrics/reasons',
                $id: '/menu/metrics/reasons',
                $name: 'Reasons'
              }
            ]
          }
        ],
      }
    ],
  },
  // {
  //   divider: true,
  // }
]

export async function getLeftMenuData() {
  return secureMenu(menuData)
}

function secureMenu(tree, newTree = []) {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i]) {
      //console.log(SD(tree[i]))
      if (tree[i].children) {
        secureMenu(tree[i].children)
      }
    }
  }
  return tree
}
