import { Tag } from "models/dsp/tag.dto";
import { ITagService } from "./itag-service";

export class MockTagService implements ITagService {

  async getTagsByAccountId(): Promise<Tag[]> {
    return [
      {
        "id": 1000031,
        "name": "v2test2update2",
        "account_id": "00000",
        "source_id": null,
        "description": "woah",
        "color": "#C0ABFF",
        "createdAt": "2024-01-30T00:51:26.000Z",
        "updatedAt": "2024-01-30T00:58:19.000Z"
      },
      {
        "id": 1000030,
        "name": "v2test1",
        "account_id": "00000",
        "source_id": null,
        "description": null,
        "color": "#C0ABFF",
        "createdAt": "2024-01-30T00:50:55.000Z",
        "updatedAt": "2024-01-30T00:50:55.000Z"
      },
      {
        "id": 1000029,
        "name": "testNew1",
        "account_id": "00000",
        "source_id": null,
        "description": "woah",
        "color": "#C0ABFF",
        "createdAt": "2024-01-30T00:49:38.000Z",
        "updatedAt": "2024-01-30T00:49:38.000Z"
      },
      {
        "id": 911500,
        "name": "testNew1",
        "account_id": "00000",
        "source_id": null,
        "description": "woah",
        "color": "#C0ABFF",
        "createdAt": "2024-01-30T00:47:28.000Z",
        "updatedAt": "2024-01-30T00:47:28.000Z"
      }]

    }
}
