import React, { createContext, useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom'

export const BreadcrumbContext = createContext(); // gets ability from provider

export const BreadcrumbProvider = (props) => {

  const updateBreadcrumbHistory = (value, link, isRoot, hash, location) => {
    let brCrbStr = localStorage.getItem('breadcrumbHistory') || '{}'
    let brCrbObj = JSON.parse(brCrbStr)
    if (isRoot) {
      brCrbObj[link] = [{ title: 'Home', path: '/' }, { title: value, path: `${link}` }]
    } else {
      // const existingHash = location.hash?.slice(1, location.hash?.length)
      const existingHash = location.pathname
      if (existingHash) {
        brCrbObj[link] = [...brCrbObj[existingHash], { title: value, path: `${link}` }]
      } else {
        brCrbObj[link] = [{ title: 'Home', path: '/' }, { title: value, path: `${link}` }]
      }
    }
    localStorage.setItem('breadcrumbHistory', JSON.stringify(brCrbObj))
  }

  return <BreadcrumbContext.Provider value={{ update: updateBreadcrumbHistory }} >
    {props.children}
  </BreadcrumbContext.Provider>

}