import { RuleGroups } from "./rule-groups.dto";
import { RuleItem } from "./creative.dto";
import { BaseCreative, CreativeDate } from "./creative.dto";



export interface VastMeta{
  vastVideoWidth?: number;
  vastVideoHeight?: number;
  vastVideoLinearity?: number;
  vastVideoDuration?: number;
  vastVideoType?: string;
  mimeType?: string;
  vastVideoProtocol?: number;
}



export interface VideosDto extends BaseCreative {
    type?: string;
    intervalStart: Date;
    intervalEnd: Date;
    vastVideoWidth?: number;
    vastVideoHeight?: number;
    vastVideoLinerarity?: number;
    vastVideoLinearity?: number;
    cur?: string;
    vastVideoDuration?: number;
    vastVideoType?: string;
    vastVideoProtocol?: number;
    vastVideoOutgoingFile?: string;
    vastVideoBitrate?: number;
    mimeType?: string;
    attr?: number[];
    siteOrApp?: string;
    categories?: string[];
    meta?: VideoMetaDataDto;
    ruleGroups?: RuleGroups
    clickthroughUrl?:string
}

export interface Video extends BaseCreative {
  interval: [CreativeDate, CreativeDate];
  categories?: string[];
  rules?: RuleItem[]
  file?: any;
  siteOrApp?:string;
  creativeType?: string
  tags?: number[]
  contentType?: string;
  attr?: number[];
  iurl?:string;
  vastTagGeneratorType: string;

  vastVideoWidth?: number;
  vastVideoHeight?: number;
  vastVideoLinearity?: number;
  vastVideoDuration?: number;
  vastVideoType?: string;
  vastVideoProtocol?: number;
  vastVideoOutgoingFile?: string;
  mimeType?: string;
  ruleGroups?: RuleGroups;
  clickThroughUrl?:string

}


export class VideoMetaDataDto {
  file?: any;
  iurl?: string;
}


export const mimeTypeOptions = [
  { value: 'v_mp4', label: 'Video/Mp4' },
  { value: 'v_ogg', label: 'Video/ogg' },
  { value: 'v_webm', label: 'Video/webm' },
  { value: 'v_mp2t', label: 'Video/MP2T' },
  { value: 'v_3gpp', label: 'Video/3gpp' },
  { value: 'v_quicktime', label: 'Video/quicktime' },
  { value: 'v_xmsvideo', label: 'Video/x-msvideo' },
  { value: 'a_xmpegurl', label: 'application/x-mpegURL' },
  { value: 'a_javascript', label: 'application/javascript' },
]

export const defaultConfigSchema = {
  audioUnmuted: false,
  omAccessMode: 'full',
  startDelayed: false,
  vpaidEnabled: true,
  vpaidPropertiesAllowedBeforeAdLoaded: true,
}

export const linerarityOptions = [
  { value: 1, label: 'Linear / In-Steam' },
  { value: 0, label: 'Non-Linear / Overlay' },
]

export const vastType = [
  { value: 'vast_1_0', label: 'VAST 1.0' },
  { value: 'vast_2_0', label: 'VAST 2.0' },
  { value: 'vast_3_0', label: 'VAST 3.0' },
  { value: 'vast_1_0_wrapper', label: 'VAST 1.0 Wrapper' },
  { value: 'vast_2_0_wrapper', label: 'VAST 2.0 Wrapper' },
  { value: 'vast_3_0_wrapper', label: 'VAST 3.0 Wrapper' },
]


