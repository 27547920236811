import React from 'react'
import styles from './style.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    {/* <div className={styles.inner}>
      <div className={styles.bottom}>
        <div className="row">
          <div className="col-sm-12">
            <div className={styles.copyright}>
              <img src="resources/images/logo/surfside-logo-black-small.svg" rel="noopener noreferrer" alt="surfside" />
              <span>
                © 2024{' '}
                <a href="https://surfside.io/" target="_blank" rel="noopener noreferrer">
                  Surfside
                </a>
                <br />
                All rights reserved
              </span>
            </div>
          </div>
        </div>
      </div>
    </div> */}
  </div>
)

export default Footer
