import React from 'react'
import styles from './style.module.scss'

const Logo = () => {
  return (
    <div >
      {/*<div className={styles.platform}>SURFSIDE</div>*/}
      <img className={styles.logo} src="/resources/images/logo/surfside-logo-black-full.svg" alt="Surfside logo" />
    </div>
  )
}

export default Logo
