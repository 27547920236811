import { Moment } from "moment";
import { BaseCreative, CreativeDate, RuleItem } from "./creative.dto";
import { RuleGroups } from "./rule-groups.dto";


export interface PresignedUrlResult {
  filename: string
  url: string
}

type BannerDate = Date | Moment; // Moment is the native type for antd date range


export interface Banner extends BaseCreative {
  interval?: [CreativeDate, CreativeDate];
  categories?: string[];
  clickThroughUrl?: string;
  file?: File;
  siteOrApp?: string;
  size: Size;
  creativeType?: string
  htmlGeneratorType?: string
  htmlTemplate?: string;
  imageUrl?: string;
  contentType?: string;
  ruleGroups?: RuleGroups;
}

export interface BannerDto extends BaseCreative {
  intervalStart: Date;
  intervalEnd: Date;
  width?: number;
  height?: number;
  htmlTemplate?: string;
  meta?: any;
  cur?: string;
  type?: string
  ruleGroups?: any
  imageUrl?: string;
  contentType?: string;
}

interface Size {
  label: string,
  value: string,
  crop: string
}

export const creativeSizes = [
  {
    label: "512x512 (1:1)",
    value: "1x1",
    crop: '512x512'
  },
  {
    label: "1600x400 (4:1)",
    value: "4x1",
    crop: '1600x400'
  },
  {
    label: "1600x267 (6:1)",
    value: "6x1",
    crop: '1600x267'
  },
  {
    label: "1600x200 (8:1)",
    value: "8x1",
    crop: '1600x200'
  },
  {
    label: "1600x160 (10:1)",
    value: "10x1",
    crop: '1600x160'

  },
  {
    label: "512x1024 (1:2)",
    value: "1x2",
    crop: '512x1024'
  },
  {
    label: "400x1600 (1:4)",
    value: "1x4",
    crop: '400x1600'
  },
  {
    label: "1000x1600 (10:16)",
    value: "10x16",
    crop: '1000x1600'
  },
  {
    label: "1600x900 (16:9)",
    value: "16x9",
    crop: '1600x900'
  },
  {
    label: "300x250",
    value: "300x250",
    crop: '300x250'
  },
  {
    label: "300x600",
    value: "300x600",
    crop: '300x600'
  },
  {
    label: "320x50",
    value: "320x50",
    crop: '320x50'
  },
  {
    label: "320x100",
    value: "320x100",
    crop: '320x100'
  },
  {
    label: "320x480",
    value: "320x480",
    crop: '320x480'
  },
  {
    label: "336x280",
    value: "336x280",
    crop: '336x280'
  },
  {
    label: "728x90",
    value: "728x90",
    crop: '728x90'
  },
  {
    label: "970x90",
    value: "970x90",
    crop: '970x90'
  },
  {
    label: "970x250",
    value: "970x250",
    crop: '970x250'
  },
  {
    label: "160x600",
    value: "160x600",
    crop: '160x600'
  }
]