import { ConnectedService } from "services/connected-service";
import { ITagService } from "./itag-service";
import { Tag, TagRelation} from "models/dsp/tag.dto";
import { ConnectedServiceResult } from "services/connected-service-result";


interface TagResult extends ConnectedServiceResult<Tag[]>{}
interface TagRelationsResult extends ConnectedServiceResult<TagRelation[]>{}

export class ConnectedTagService extends ConnectedService implements ITagService {

  constructor(url: string) {
    super(url);
  }



  async getTagsByAccountId(): Promise< Tag[]> {
    const result = await this._get<TagResult>(`tags/all`);
    if(result.success === true && result.data !== undefined){
      return result.data;
    }
    throw new Error(result.message);
  }

  async getTagsByCreativeId(creativeId: string): Promise< TagRelation[]> {
    const result = await this._post<TagRelationsResult>(`tags/get_relations`, {
      target_id: String(creativeId), 
    });
    if(result.success === true && result.data !== undefined){
      return result.data;
    }
    throw new Error(result.message);
  }

}