import { ConnectedService } from 'services/connected-service'
import { IUploadService, UploadedFile } from './iupload-service'
import { CreativeUtilHelper } from '../creative-util/creative-util-helper'
import { ConnectedServiceResult } from 'services/connected-service-result'
import {
  UploadedFolderItemResponseDto,
  UploadedFolderResponseDto,
} from 'models/upload/uploaded-files.dto'
import JSZip, { JSZipObject } from 'jszip'
import { message } from 'antd'

interface UploadedFolderResult extends ConnectedServiceResult<UploadedFolderResponseDto> {}
interface UploadedFileResult extends ConnectedServiceResult<UploadedFolderItemResponseDto> {}

export class ConnectedUploadService extends ConnectedService implements IUploadService {
  async uploadZipFolder(
    blob: Blob,
    onSuccess: () => void,
    onError: () => void,
  ): Promise<UploadedFile[]> {
    var newZip = new JSZip()
    const zip = await newZip.loadAsync(blob)

    const files = await Promise.all(
      Object.entries(zip.files).map(async ([key, value]: [string, JSZipObject]): Promise<File> => {
        const blob = await value.async('blob')
        return new File([blob], key, { type: blob.type })
      }),
    )

    return await this.uploadFolder(files, onSuccess, onError)
  }

  async uploadFolder(
    files: File[],
    onSuccess: () => void,
    onError: () => void,
  ): Promise<UploadedFile[]> {
    const result = await this._post<UploadedFolderResult>('dsp/banners/upload/folder', {
      filenames: files.map((f) => f.name),
    })
    if (!result.success || result.data === undefined) {
      onError()
      CreativeUtilHelper.errorMessage(result.message || 'folder upload failed')
      throw new Error(result.message)
    }
    await Promise.all(
      result.data.items.map((item) => {
        const file = files.find((f) => decodeURIComponent(item.filename).endsWith(f.name))
        if (file === undefined) {
          onError()
          CreativeUtilHelper.errorMessage(`File not found: ${item.filename}`)
          // throw new Error(`File not found: ${item.filename}`)
        }

        const success = this._uploadFile(file!, item.url)
        if (!success) {
          onError()
          CreativeUtilHelper.errorMessage(`Failed to upload file: ${item.filename}`)
          // throw new Error(`Failed to upload file: ${item.filename}`)
        }
      }),
    )

    onSuccess()
    CreativeUtilHelper.successMessage('Zip file folder is uploaded')

    return result.data.items.map((item) => ({
      url: item.filename,
      filename: files.find((f) => item.filename.endsWith(f.name))?.name || item.filename,
    }))
  }

  async uploadFile(file: File, onSuccess: () => void, onError: () => void): Promise<UploadedFile> {
    const result = await this._post<UploadedFileResult>('dsp/banners/upload', {
      filename: file.name.split('.')[0],
      extension: file.name.split('.').pop() || '',
    })

    if (!result.success || result.data === undefined) {
      onError()
      CreativeUtilHelper.errorMessage('file upload failed')
      throw new Error(result.message)
    }
    const success = await this._uploadFile(file, result.data.url)
    if (success) {
      onSuccess()
      CreativeUtilHelper.successMessage('file upload success')
      return {
        url: result.data.filename,
        filename: file.name,
      }
    }
    onError()
    CreativeUtilHelper.errorMessage('file upload failed')
    throw new Error('Failed to upload file')
  }

  private async _uploadFile(file: File, url: string): Promise<boolean> {
    const result = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    })
    if (result.status === 200) {
      return true
    }
    return false
  }

 
}
