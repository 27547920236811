import Cookies from "js-cookie"

export abstract class ConnectedService {
  constructor(protected readonly _url: string) { }

  getToken(): string | undefined {
    return Cookies.get('auth0-jwt');
  }

  protected async _get<T>(path: string): Promise<T> {
    return this._fetchApi(path, 'GET');
  }

  protected async _put<T>(path: string, body: any): Promise<T> {
    return this._fetchApi(path, 'PUT', body);
  }

  protected async _post<T>(path: string, body: any): Promise<T> {
    return this._fetchApi(path, 'POST', body);
  }

  protected async _patch<T>(path: string, body: any): Promise<T> {
    return this._fetchApi(path, 'PATCH', body);
  }

  protected async _fetchApi<T>(path: string, method: string, body?: any): Promise<T> {
    const token = this.getToken();
    if (!token) {
      throw new Error('No token found');
    }

    const response = await fetch(`${this._url}/v2/${path}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method,
      body: body ? JSON.stringify(body) : undefined
    });

    let data = await response.json();

    if (!response.ok) {
      // throw new Error(data.message);
      console.log(data.message)
    }

    return data;
  }
}