import { Account } from "models/iam/account.dto";
import { IAccountService } from "./iaccount-service";
import { ConnectedService } from "services/connected-service";
import { ConnectedServiceResult } from "services/connected-service-result";

interface AccountResults extends ConnectedServiceResult<Account[]>{}
interface AccountResult extends ConnectedServiceResult<Account>{}

export class ConnectedAccountService extends ConnectedService implements IAccountService {
  constructor(url: string){
    super(url);
  }
  async getAccounts(): Promise<Account[]> {
    const response = await this._fetchApi<AccountResults>('accounts', 'GET');
    if(response.success === true && response.data !== undefined){
      return response.data;
    }
    throw new Error(response.message);
  }

  async getAccountById(id: number): Promise<Account> {
    const result = await this._fetchApi<AccountResult>(`accounts/${id}`, 'GET');
    if(result.success === true && result.data !== undefined){
      return result.data;
    }
    throw new Error(result.message);
  }
}
