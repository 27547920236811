import React, { useState, useRef, useEffect } from 'react'
import { BackTop, Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
import Footer from 'components/LayoutComponents/Footer'
import Breadcrumbs from 'components/LayoutComponents/Breadcrumbs'
import Settings from 'components/LayoutComponents/Settings'

const mapStateToProps = ({ settings }) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
})

//@withRouter
//@connect(mapStateToProps)
const MainLayout = (props) => {
  const [linkValue, setLinkValue] = useState()

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setLinkValue(event.target.innerHTML)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)
  // render() {
  const { children, isBorderless, isSquaredBorders, isFixedWidth, isMenuShadow, isMenuTop } = props


  return (
    <Layout
      className={classNames({
        settings__borderLess: isBorderless,
        settings__squaredBorders: isSquaredBorders,
        settings__fixedWidth: isFixedWidth,
        settings__menuShadow: isMenuShadow,
        settings__menuTop: isMenuTop,
      })}
    >
      {/* <BackTop /> */}
      <Menu />
      <Settings />
      <Layout>
        <Layout.Header className="layoutHeader">
          <TopBar />
          <div ref={wrapperRef}>
            <Breadcrumbs linkValue={linkValue} />
          </div>
        </Layout.Header>
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          <div className="utils__content" style={{ paddingTop: '5rem' }}>
            {children}
          </div>
        </Layout.Content>
        {/* <Layout.Footer>
          <Footer />
        </Layout.Footer> */}
      </Layout>
    </Layout>
  )
}
// }

export default withRouter(connect(mapStateToProps)(MainLayout))
