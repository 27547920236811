import React, { createContext, useRef, useState } from 'react';
import { createContextualCan } from '@casl/react';
import { Ability } from '@casl/ability';
import { useSurfsideApiOnUpdate } from 'hooks/surfside-service'
import { useDeepCompareEffect } from 'hooks/deep-compare-effect';
import { useAuth0 } from './auth0'
import Cookies from 'js-cookie';
const ability = (ability) => {
  return new Ability(ability)
}


export const AbilityContext = createContext(); // gets ability from provider

export const AbilityProvider = (props) => {
  const abilityRef = useRef();
  const isInitialMount = useRef(true)
  const auth0 = useAuth0()
  const { user, loading } = auth0;
  const [userId, setUserId] = useState()
  const [token, setToken] = useState()
  const path = `dmp/userroles/auth0/id/${userId}?casl=true`;
  const [_ability, setAbility] = useState()
  const [abilityLoading, setAabilityLoading] = useState(true)

  const [loadingCasl, , error, dataRaw] = useSurfsideApiOnUpdate({
    method: 'POST',
    path: 'iam/listPrivileges?casl=true',
    payload: {
      type: 'listPrivileges',
      query: {
        namespace: '/surfside-dmp',
      },
    },
    dependencies: [token],
    token: token
  }, isInitialMount)

  useDeepCompareEffect(() => {
    if (user && user.sub) {
      setUserId(user.sub.split('|')[1])
      setTimeout(() => {
        setToken(Cookies.get('user'))
      }, 1000)
    }
  }, [user || {}])



  useDeepCompareEffect(() => {
    if (dataRaw && dataRaw.casl) {
      abilityRef.current =  ability(dataRaw.casl);
      setAabilityLoading(false)
    }
  }, [dataRaw || {}])

  if (!user) {
    //     return <div>new user</div>
  }

  return <AbilityContext.Provider value={[abilityRef.current, loading || loadingCasl || abilityLoading, error]} >
    {props.children}
  </AbilityContext.Provider>
}

export const AbilityConsumer = (props) => {
  return <AbilityContext.Consumer>
    {(authorizeData) => { return props.children(authorizeData[0]) }}
  </AbilityContext.Consumer>
}

export const CanWithAbility = createContextualCan(AbilityConsumer)

export const Can = createContextualCan(AbilityContext.Consumer);  // can is the conumer here 

