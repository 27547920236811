import { ConnectedService } from "services/connected-service";
import { ITargetService } from "./itarget-service";
import { Target } from "models/dsp/target.dto";
import { ConnectedServiceResult } from "services/connected-service-result";
import { APIQuery } from "models/query/query.dto";

interface TargetResult extends ConnectedServiceResult<Target> { }
interface TargetResults extends ConnectedServiceResult<Target[]> { }

export class ConnectedTargetService extends ConnectedService implements ITargetService {
  async create(target: Target): Promise<Target> {
    const newTarget = await this._post<TargetResult>('dsp/targets', target);
    if (newTarget.success) {
      return newTarget.data!;
    } else {
      throw new Error(newTarget.message);
    }
  }

  async update(target: Target): Promise<Target> {
    const updated = await this._put<TargetResult>('dsp/targets', target);
    if (updated.success) {
      return updated.data!;
    } else {
      throw new Error(updated.message);
    }
  }

  async getById(id: number): Promise<Target | null> {
    try {
      const target = await this._get<TargetResult>(`dsp/targets/${id}`);
      if (target.success) {
        return target.data!;
      }
    } catch (e) {
      return null;
    }
    return null;
  }

  async getTargets(query?: string, page?: number | undefined, limit?: number | undefined): Promise<Target[]> {
    try {
      const results = (!query && !page && !limit)
        ? await this._get<TargetResults>('dsp/targets')
        : await this._get<TargetResults>('dsp/targets?page=' + page + '&limit=' + limit + (query ? '&query=' + query : ''))
      if (results.success) {
        return results.data ?? [];
      }
      throw new Error(results.message);
    } catch (e) {
      return [];
    }
  }

  async queryTargets(_analyticFilter: any[], query: APIQuery): Promise<Target[]> {
    const response = await this._post<TargetResults>('dsp/targets/query', query)
    if ( response.data !== undefined) {
      return response.data
    }
    throw new Error(response.message)
  }
}