
import { IBannerService} from "./ibanner-service";
import { PresignedUrlResult } from "models/dsp/banner.dto";


export class MockBannerService implements IBannerService {
  async getS3PresignedUrl(fileName:string, extension:string): Promise<PresignedUrlResult> {
    return  {
        url: "https://surf-creative-assets-test.s3.us-east-1.amazonaws.com/creatives/5fda2.gif?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAR2BGNO47ZZFE4VYS%2F20240314%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240314T201308Z&X-Amz-Expires=7200&X-Amz-Signature=4b8db387863c333abf297a9484891db4caac223bc34038b69e030697fe50bfa8&X-Amz-SignedHeaders=host&x-id=PutObject",
        filename: "https://surf-creative-assets-test.s3.us-east-1.amazonaws.com/creatives/5fda2.gif"
    }
  }

  async getS3PresignedUrls(filenames:{filenames:string[]}): Promise<PresignedUrlResult[]> {
    return [{
        url: "https://surf-creative-assets-test.s3.us-east-1.amazonaws.com/creatives/5fda2.gif?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAR2BGNO47ZZFE4VYS%2F20240314%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240314T201308Z&X-Amz-Expires=7200&X-Amz-Signature=4b8db387863c333abf297a9484891db4caac223bc34038b69e030697fe50bfa8&X-Amz-SignedHeaders=host&x-id=PutObject",
        filename: "https://surf-creative-assets-test.s3.us-east-1.amazonaws.com/creatives/5fda2.gif"
    },
    {
      url: "https://surf-creative-assets-test.s3.us-east-1.amazonaws.com/creatives/5fda2.gif?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAR2BGNO47ZZFE4VYS%2F20240314%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240314T201308Z&X-Amz-Expires=7200&X-Amz-Signature=4b8db387863c333abf297a9484891db4caac223bc34038b69e030697fe50bfa8&X-Amz-SignedHeaders=host&x-id=PutObject",
      filename: "https://surf-creative-assets-test.s3.us-east-1.amazonaws.com/creatives/5fda2.gif"
  }]
  }
}
