import { ConnectedService } from "services/connected-service";
import { ConnectedServiceResult } from "services/connected-service-result";
import { UserMeta } from "models/user-meta/user-meta.dto";
import { IUserMetaService } from "./iuser-meta-service";

interface UserMetaResult extends ConnectedServiceResult<UserMeta>{}

export class ConnectedUserMetaService extends ConnectedService implements IUserMetaService {
  constructor(url: string){
    super(url);
  }

  async getUserMeta(): Promise<UserMeta> {
    const result = await this._fetchApi<UserMetaResult>(`user-meta`, 'GET');
    if(result.success === true && result.data !== undefined){
      return result.data;
    }
    throw new Error(result.message);
  }
}
