import { BannerQueryResult, BannerSearchResult, CreativeSearchResult, NativeQueryResult, NativeSearchResult, PaginatedSearchResult, VideoQueryResult, VideoSearchResult } from "models/dsp/creative-search-result.dto";
import { ICreativeService } from "./icreative-service";
import { Adset, CreateAdset, UpdateAdset } from "models/dsp/adset.dto";

const images: string[] = [
  "https://cdn.surfside.io/assets/images/1.gif",
  "https://cdn.surfside.io/assets/images/2.jpg",
  "https://cdn.surfside.io/assets/images/3.jpg",
  "https://cdn.surfside.io/assets/images/4.jpg",
  "https://cdn.surfside.io/assets/images/5.png",
]

const videos = [
  ["http://surf-creative-assets-test.s3.amazonaws.com/mock/creatives/big_buck_bunny_240p_1mb.mp4", "http://surf-creative-assets-test.s3.amazonaws.com/mock/creatives/big_buck_bunny_240p_1mb.mp4_thumbnail.jpg"],
  ["http://surf-creative-assets-test.s3.amazonaws.com/mock/creatives/big_buck_bunny_240p_5mb.mp4", "http://surf-creative-assets-test.s3.amazonaws.com/mock/creatives/big_buck_bunny_240p_5mb.mp4_thumbnail.jpg"],
  ["http://surf-creative-assets-test.s3.amazonaws.com/mock/creatives/big_buck_bunny_480p_10mb.mp4", "http://surf-creative-assets-test.s3.amazonaws.com/mock/creatives/big_buck_bunny_480p_10mb.mp4_thumbnail.jpg"],
  ["http://surf-creative-assets-test.s3.amazonaws.com/mock/creatives/big_buck_bunny_480p_2mb.mp4", "http://surf-creative-assets-test.s3.amazonaws.com/mock/creatives/big_buck_bunny_480p_2mb.mp4_thumbnail.jpg"],
  ["http://surf-creative-assets-test.s3.amazonaws.com/mock/creatives/big_buck_bunny_720p_5mb.mp4", "http://surf-creative-assets-test.s3.amazonaws.com/mock/creatives/big_buck_bunny_720p_5mb.mp4_thumbnail.jpg"],
]

const nativeImages: string[] = [
  "https://cdn.surfside.io/assets/images/native_1.jpg",
  "https://cdn.surfside.io/assets/images/native_2.png",
  "https://cdn.surfside.io/assets/images/native_3.gif",
  "https://cdn.surfside.io/assets/images/native_4.gif",
  "https://cdn.surfside.io/assets/images/native_5.png"
];

const names: string[] = [
  "TechForge | DataPulse",
  "CodeStream | FlowArchitect",
  "NovaNet | SkySync",
  "QuantumScape | QuantumCore",
  "CyberNest | ShieldPro",
  "TechForge | CodeCraft",
  "CodeStream | StreamlineSDK",
  "NovaNet | HorizonLink",
  "QuantumScape | InfinityWorks",
  "CyberNest | CyberGuardian",
  "BeaconTech | BeaconBoard",
  "VisionWare | Clarity360",
  "PixelPioneer | PixelCraft",
  "DataDynamics | DataSphere",
  "CloudCrafter | NebulaNest",
  "BeaconTech | LighthouseLogic",
  "VisionWare | InsightEngine",
  "PixelPioneer | ImageInnovate",
  "DataDynamics | QuantumQuery",
  "CloudCrafter | StratoStream",
  "TechForge | AlloyAPI",
  "CodeStream | CodeCompass",
  "NovaNet | VirtualVertex",
  "QuantumScape | VortexValve",
  "CyberNest | FirewallForte",
  "InfoInnova | InfoInsight",
  "LogicLeap | LeapLink",
  "MetaMatrix | MatrixMind",
  "EchoEngine | EchoElevate",
  "FusionFactor | FusionForge",
  "InfoInnova | InnovateIntegrate",
  "LogicLeap | LogicLattice",
  "MetaMatrix | MetaMingle",
  "EchoEngine | EchoEcho",
  "FusionFactor | FactorFlow",
  "TechForge | ForgeFramework",
  "CodeStream | StreamlineStudio",
  "NovaNet | NetNucleus",
  "QuantumScape | QuantaQuest",
  "CyberNest | NestNet",
  "BeaconTech | BeaconBase",
  "VisionWare | VisionVault",
  "PixelPioneer | PioneerPixels",
  "DataDynamics | DynamicDataDrive",
  "CloudCrafter | CloudConstruct",
  "InfoInnova | InsightInnova",
  "LogicLeap | LogicLayer",
  "MetaMatrix | MetaMerge",
  "EchoEngine | EngineEchoes",
  "FusionFactor | FusionFront",
  "TechForge | NexusNode",
  "CodeStream | ByteBridge",
  "NovaNet | LinkLattice",
  "QuantumScape | QuasarQuest",
  "CyberNest | SentrySphere",
  "TechForge | FusionFrame",
  "CodeStream | CodeCatalyst",
  "NovaNet | NetNavigate",
  "QuantumScape | ElementEcho",
  "CyberNest | DigitalDefender",
  "BeaconTech | SignalSync",
  "VisionWare | ViewVantage",
  "PixelPioneer | PrismPro",
  "DataDynamics | DatumDrive",
  "CloudCrafter | CumulusCraft",
  "BeaconTech | BeaconBeam",
  "VisionWare | VisionaryValve",
  "PixelPioneer | PixelPlanner",
  "DataDynamics | DynamicDepth",
  "CloudCrafter | CloudCanvas",
  "TechForge | TechTorch",
  "CodeStream | CipherCircuit",
  "NovaNet | NovaNode",
  "QuantumScape | QuantaQuill",
  "CyberNest | NetNestor",
  "InfoInnova | InsightInfinity",
  "LogicLeap | LuminousLogic",
  "MetaMatrix | MindMeld",
  "EchoEngine | EchoEstate",
  "FusionFactor | FusionFlux",
  "InfoInnova | InfiniteInnovate",
  "LogicLeap | LogicLoom",
  "MetaMatrix | MetaMorph",
  "EchoEngine | EtherealEcho",
  "FusionFactor | FactorFusion",
  "TechForge | TemplateTech",
  "CodeStream | CommandCode",
  "NovaNet | NexusNet",
  "QuantumScape | QuantaQuench",
  "CyberNest | CyberClarity",
  "BeaconTech | BitBeacon",
  "VisionWare | VistaVision",
  "PixelPioneer | PixelPulse",
  "DataDynamics | DataDimension",
  "CloudCrafter | CirrusCraft",
  "InfoInnova | InnovateInfinity",
  "LogicLeap | LatticeLogic",
  "MetaMatrix | MatrixMingle",
  "EchoEngine | EssenceEcho",
  "FusionFactor | FutureFusion"
];

export class MockCreativeService implements ICreativeService {

  private banners: BannerSearchResult[];
  private videos: VideoSearchResult[];
  private natives: NativeSearchResult[];

  private adsets: Map<string, Adset>;

  constructor() {
    this.banners = [];
    this.videos = [];
    this.natives = [];
    this.adsets = new Map();

    for (let i = 0; i < names.length; i++) {
      this.banners.push({
        id: Math.floor(Math.random() * 0xffffffff),
        name: names[i],
        createdAt: new Date(2001 + Math.floor(Math.random() * 20), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)),
        startDate: new Date(2001 + Math.floor(Math.random() * 20), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)),
        endDate: new Date(2001 + Math.floor(Math.random() * 20), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)),
        sourceId: Math.random().toString(16).substring(2, 8),
        url: images[Math.floor(Math.random() * images.length)],
      });
    }

    for(let i = 0; i < 200; i++) {

      const name = names[i % names.length];
      const video = videos[Math.floor(Math.random() * videos.length)];
      const url = video[0];
      // 25% chance that thumbnail is undefined
      const thumbnail = Math.random() > 0.25 ? video[1] : undefined;

      this.videos.push({
        name,
        url,
        thumbnail,
        id: Math.floor(Math.random() * 0xffffffff),
        sourceId: Math.random().toString(16).substring(2, 8),
        createdAt: new Date(2001 + Math.floor(Math.random() * 20), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)),
        startDate: new Date(2001 + Math.floor(Math.random() * 20), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)),
        endDate: new Date(2001 + Math.floor(Math.random() * 20), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)),
      })
    }

    for(let i = 0; i < 200; i++){
      this.natives.push({
        id: Math.floor(Math.random() * 0xffffffff),
        name: names[i % names.length],
        createdAt: new Date(2001 + Math.floor(Math.random() * 20), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)),
        startDate: new Date(2001 + Math.floor(Math.random() * 20), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)),
        endDate: new Date(2001 + Math.floor(Math.random() * 20), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)),
        sourceId: Math.random().toString(16).substring(2, 8),
        image: nativeImages[Math.floor(Math.random() * nativeImages.length)]
      });
    }
  }
  async updateAdset(adset: UpdateAdset): Promise<Adset> {
    const existing = this.adsets.get(adset.id);
    if(!existing){
      throw new Error(`Adset with id ${adset.id} not found`);
    }
    const updated = {
      ...adset,
      banners: adset.banners.map(id => ({ id, name: "banner" })),
      videos: adset.videos.map(id => ({ id, name: "video" })),
      natives: adset.natives.map(id => ({ id, name: "native" })),
    };
    this.adsets.set(adset.id, updated);
    return updated;
  }

  async getAdSetById(adsetId: string): Promise<Adset | null> {
    const adset = this.adsets.get(adsetId);
    if (adset) {
      return adset;
    }
    return null;
  }

  async createAdset(adset: CreateAdset): Promise<Adset>{
    const id = Math.random().toString(16).substring(2, 8);
    const newAdset = {
      ...adset,
      id,
      banners: adset.banners.map(id => ({ id, name: "banner" })),
      videos: adset.videos.map(id => ({ id, name: "video" })),
      natives: adset.natives.map(id => ({ id, name: "native" })),
    };
    this.adsets.set(id, newAdset);
    return newAdset;
  }

  async query<T extends PaginatedSearchResult>(query: string | undefined, page: number, pageSize: number, creatives: CreativeSearchResult[], fieldName: keyof T): Promise<T> {
    const results = query
      ? creatives.filter(creative => creative.name.includes(query))
      : creatives;

    const total = results.length;
    return {
      [fieldName]: results.slice((page - 1) * pageSize, page * pageSize) as unknown as any,
      total,
      page,
    } as T;
  }

  async queryBanners(query: string | undefined, page: number, pageSize: number): Promise<BannerQueryResult> {
    return await this.query(query, page, pageSize, this.banners, 'banners');
  }

  async queryVideos(query: string | undefined, page: number, pageSize: number): Promise<VideoQueryResult> {
    return await this.query(query, page, pageSize, this.videos, 'videos');
  }

  async queryNatives(query: string | undefined, page: number, pageSize: number): Promise<NativeQueryResult> {
    return await this.query(query, page, pageSize, this.natives, 'natives');
  }
}