import { ConnectedService } from "services/connected-service";
import { IStrategyService } from "./istrategy-service";
import { LineItemDto, LineItem, LineItemAttribute, Budgets, BudgetList } from "models/dsp/lineItem.dto";
import { ConnectedServiceResult } from "services/connected-service-result";
import moment from "moment";
import { APIQuery } from "models/query/query.dto";

interface LineItemResult extends ConnectedServiceResult<LineItemDto>{}
interface LineItemResults extends ConnectedServiceResult<LineItemDto[]>{}
interface LineItemQueryResults extends ConnectedServiceResult<LineItem[]>{}

enum BudgetPeriodDb {
  HOURLY = 1,
  DAILY = 24,
  WEEKLY = 168,
  MONTHLY = 720,
  YEARLY = 8760,
  TOTAL = -1

}

enum BudgetTypeDb {
  IMPRESSIONS = 2,
  DOLLARS = 1,
  BUDGET = 1
}

export class ConnectedStrategyService extends ConnectedService implements IStrategyService {

  constructor(url: string) {
    super(url);
  }

  private mapDtoToStrategy(dto: LineItemDto): LineItem {

    let attributes: LineItemAttribute[] = [];

    if (dto.strategy !== undefined && dto.strategy !== null) {
      attributes.push({ key: 'Strategy', value: dto.strategy });
    }
    if (dto.campaign !== undefined && dto.campaign !== null) {
      attributes.push({ key: 'Campaign', value: dto.campaign });
    }
    if (dto.store !== undefined && dto.store !== null) {
      attributes.push({ key: 'Store', value: dto.store });
    }
    if (dto.market !== undefined && dto.market !== null) {
      attributes.push({ key: 'Market', value: dto.market });
    }
    if (dto.location !== undefined && dto.location !== null) {
      attributes.push({ key: 'Location', value: dto.location });
    }

    if (dto.contractedCpm !== undefined && dto.contractedCpm !== null) {
      attributes.push({ key: 'Contracted CPM', value: dto.contractedCpm });
    }

    if (dto.contractedTotalSpend !== undefined && dto.contractedTotalSpend !== null) {
      attributes.push({ key: 'Contracted Total Spend', value: dto.contractedTotalSpend });
    }

    return {
      id: dto.id,
      accountId: dto.accountId,
      name: dto.name,
      sourceId: dto.sourceId,
      impressions: dto.impressions,
      budget: dto.budget,
      budgets: this.mapBudgetListToBudgets(dto.budgetList || []),
      // budget: mapToBudgetList(dto.budgets),
      interval: [moment(dto.startDate), moment(dto.endDate)],
      createdAt: dto.createdAt,
      updatedAt: dto.updatedAt,
      attributes: attributes
    }
  }

  private mapStrategyToDto(strategy: LineItem): LineItemDto {
    return {
      id: strategy.id,
      accountId: strategy.accountId,
      name: strategy.name,
      sourceId: strategy.sourceId,
      impressions: strategy.impressions,
      budget: strategy.budget,
      budgetList: this.mapBudgetsToBudgetList(strategy.budgets || []),
      contractedCpm: this.getAttributeByKey(strategy.attributes, 'Contracted CPM'),
      contractedTotalSpend: this.getAttributeByKey(strategy.attributes, 'Contracted Total Spend'),
      strategy: this.getAttributeByKey(strategy.attributes, 'Strategy'),
      store: this.getAttributeByKey(strategy.attributes, 'Store'),
      campaign: this.getAttributeByKey(strategy.attributes, 'Campaign'),
      market: this.getAttributeByKey(strategy.attributes, 'Market'),
      location: this.getAttributeByKey(strategy.attributes, 'Location'),
      startDate: strategy.interval![0] as Date,
      endDate: strategy.interval[1] as Date,
    }
  }

  private mapBudgetsToBudgetList(budgets: Budgets[]): BudgetList[] {
    const filteredBudgets = budgets.filter(b => b.amount)
    return filteredBudgets.map(b => {
      let currPeriod: keyof typeof BudgetPeriodDb;
      currPeriod = b.period?.toUpperCase() as keyof typeof BudgetPeriodDb;

      let currType: keyof typeof BudgetTypeDb;
      currType = b.type?.toUpperCase() as keyof typeof BudgetTypeDb;

      let currAmount = b.amount

      return ({
        period: BudgetPeriodDb[currPeriod],
        type: BudgetTypeDb[currType],
        amount: currAmount
      })
    })
  }

  private mapBudgetListToBudgets(budgets: BudgetList[]): Budgets[] {

    const budgetPeriodDb = {
      '1': 'Hourly',
      '24': 'Daily',
      '168': 'Weekly',
      '720': 'Monthly',
      '8760': 'Yearly',
      '-1': 'Total'
    }

    const budgetTypeDbs = {
      '1': 'Dollars',
      '2': 'Impressions',
    }

    return budgets.map(b => {
      let currPeriod: keyof typeof budgetPeriodDb;
      currPeriod = b.period.toString() as keyof typeof budgetPeriodDb;

      let currType: keyof typeof budgetTypeDbs;
      currType = b.type?.toString() as keyof typeof budgetTypeDbs;
      let currAmount = b.amount

      if (typeof currAmount === 'string') {
        currAmount = parseInt(currAmount)
      }

      return ({
        period: budgetPeriodDb[currPeriod],
        type: budgetTypeDbs[currType],
        amount: currAmount
      })
    })
  }

  private getAttributeByKey(attributes: LineItemAttribute[], key: string): any | undefined {
    const attribute = attributes?.find((a) => a.key === key);
    return attribute?.value;
  }

  async getStrategies(sourceId?: string): Promise<LineItem[]> {
    let path = 'dsp/strategies';
    if (sourceId) {
      path += `?sourceId=${sourceId}`;
    }
    const result = await this._get<LineItemResults>(path);
    if (result.success === true && result.data !== undefined) {
      return result.data.map((dto) => this.mapDtoToStrategy(dto));
    }
    throw new Error(result.message);
  }

  async queryStrategies(query: APIQuery): Promise<LineItem[]> {
    const response = await this._post<LineItemQueryResults>('dsp/lineItems/query', query)
    if (response.success === true && response.data !== undefined) {
      return response.data
    }
    throw new Error(response.message)
  }

  async getStrategyById(id: string): Promise<LineItem | null> {
    const result = await this._get<LineItemResult>(`dsp/strategies/${id}`);
    if (result.success === true && result.data !== undefined) {
      return this.mapDtoToStrategy(result.data);
    }
    throw new Error(result.message);
  }

  async createStrategy(LineItem: LineItem): Promise<LineItem> {
    const result = await this._post<LineItemResult>('dsp/strategies', this.mapStrategyToDto(LineItem));
    if (result.success === true && result.data !== undefined) {
      return this.mapDtoToStrategy(result.data);
    }
    throw new Error(result.message);
  }

  async updateStrategy(lineItem: LineItem): Promise<LineItem> {
    const result = await this._patch<LineItemResult>(`dsp/strategies`, this.mapStrategyToDto(lineItem));
    if (result.success === true && result.data !== undefined) {
      return this.mapDtoToStrategy(result.data);
    }
    throw new Error(result.message);
  }

}