import { Coordinate, Target } from "models/dsp/target.dto";
import { ITargetService } from "./itarget-service";
import { Countries } from "components/SurfsideUIComponents/Dsp/Targeting/Countries";
import { CanadaRegions, USRegions } from "components/SurfsideUIComponents/Dsp/Targeting/Regions";
import { Carriers, DeviceMake, DeviceTypes, OperatingSystems } from "components/SurfsideUIComponents/Dsp/Targeting/Devices";

export class MockTargetService implements ITargetService {

  private readonly coordinates: Coordinate[] = [
    {
      lat: 40.43213590785216,
      lon: 116.5704177750072,
      radius: 1000
    }, {
      lat: 27.175182603623274,
      lon: 78.04209923869624,
      radius: 500
    }, {
      lat: 30.32875033509069,
      lon: 35.44431923982251,
      radius: 2000
    }, {
      // 41.890113899900136, 12.492445437134268
      lat: 41.890113899900136,
      lon: 12.492445437134268,
      radius: 500
    }, {
      // 39.987937831633545, 15.720790459439376
      lat: 39.987937831633545,
      lon: 15.720790459439376,
      radius: 500
    }, {
      // 20.679750278126978, -88.56681929488471
      lat: 20.679750278126978,
      lon: -88.56681929488471,
      radius: 500
    }, {
      //-13.163198595476343, -72.54504373894518
      lat: -13.163198595476343,
      lon: -72.54504373894518,
      radius: 500
    }
  ]

  private readonly categories: string[] = [
    'IAB151',
    'IAB179',
    'IAB181',
    'IAB153',
    'IAB154',
    'IAB155',
    'IAB158',
    'IAB159',
    'IAB160',
    'IAB177',
    'IAB178'
  ];

  targets: Target[];

  private randomInt(max: number): number {
    return Math.floor(Math.random() * max);
  }

  private randomSubarray<T>(t: T[], max: number = 0xffffffff): T[] {
    let start = this.randomInt(t.length);
    let end = Math.min(max, this.randomInt(t.length - start)) + start;
    return t.slice(start, end);
  }

  constructor() {
    this.targets = [];
    for (let i = 0; i < 200; i++) {

      let randomInt = Math.floor(Math.random() * 100);

      //generate base16 ID
      let randomId = () => Math.floor(Math.random() * 16).toString(16);
      let countries: string[] = [];
      if (randomInt < 80) {
        countries.push('USA');
      }
      countries.push(Countries[Math.floor(Math.random() * Countries.length)].value);

      let regions: string[] = [];
      if (countries.includes('USA')) {
        regions.push(USRegions[Math.floor(Math.random() * USRegions.length)].value);
      }
      if (countries.includes('CAN')) {
        regions.push(CanadaRegions[Math.floor(Math.random() * CanadaRegions.length)].value);
      }

      this.targets.push({
        id: Math.floor(Math.random() * 0xffffffff),
        sourceId: randomId(),
        accountId: randomId(),
        name: `Target ${i}`,
        interval: [new Date(), new Date()],
        tags: [randomInt],
        geo: (randomInt < 50 ? {
          type: 'country',
          country: countries,
          region: regions
        } : {
          type: 'coordinates',
          coordinates: this.randomSubarray(this.coordinates)
        }),
        device: {
          os: this.randomSubarray(OperatingSystems, 3).map((os) => os.value),
          make: this.randomSubarray(DeviceMake, 3).map((make) => make.value),
          model: this.randomSubarray(DeviceMake, 3).map((model) => model.value),
          carrier: this.randomSubarray(Carriers, 3).map((carrier) => carrier.value),
          type: this.randomSubarray(DeviceTypes, 2).map((type) => type.value)
        },
        publisher: {
          include: this.randomSubarray(this.categories, 3),
          exclude: this.randomSubarray(this.categories, 3)
        }
      })
    }
  }

  async create(target: Target): Promise<Target> {
    if (target.id) {
      delete target.id;
    }
    this.targets.push(target);
    return target;
  }

  async update(target: Target): Promise<Target> {
    let index = this.targets.findIndex((t) => t.id === target.id);
    if (index >= 0) {
      this.targets[index] = target;
    } else {
      throw new Error("Not found");
    }
    return target;
  }

  async getById(id: number): Promise<Target | null> {
    let target = this.targets.find((t) => t.id === id);
    return target ? target : null;
  }

  async getTargets(query?: string, page: number | undefined = 0, limit: number | undefined = 10): Promise<Target[]> {
    return this.targets
      .filter(target => target.name.toLowerCase().includes(query?.toLowerCase() ?? ''))
      .slice(page * limit, (page + 1) * limit);
  }
}