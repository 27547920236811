export const DeviceTypes = [
  "Connected Device",
  "Connected TV",
  "Mobile/Tablet",
  "Personal Computer",
  "Phone",
  "Set Top Box",
  "Tablet"
].map((device) => ({ value: device, label: device }));

export const Carriers = [
  "AT&T",
  "Verizon",
  "T-Mobile",
  "Dish Wireless",
  "UScellular",
  "Google Fi",
  "Mint Mobile"
].map((carrier) => ({ value: carrier, label: carrier }));

export const DeviceMake = [
  "Apple",
  "Samsung",
  "LG",
  "Motorola",
  "Google",
  "OnePlus",
  "Sony",
  "Nokia",
  "HTC",
  "Huawei",
  "ZTE",
  "Microsoft",
  "Xiaomi",
].map((make) => ({ value: make, label: make }));

export const DeviceModel = [
  "iPhone",
  "Galaxy",
  "Pixel",
  "Redmi",
  "Windows",
  "Macintosh",
].map((model) => ({ value: model, label: model }));

export const OperatingSystems = [
  "iOS",
  "Android",
  "Windows",
  "macOS",
  "Linux",
  "Chrome OS",
  "Fire OS",
  "Roku OS"
].map((os) => ({ value: os, label: os }));