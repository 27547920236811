import { IUserMetaService } from "./iuser-meta-service";
import { UserMeta } from "models/user-meta/user-meta.dto";

export class MockUserMetaService implements IUserMetaService {
  async getUserMeta(): Promise<UserMeta> {
    return {
      "advertising": true,
      "digital": true,
      "physical": true,
      "pos": true,
      "thirdparty": true,
      "crm": true,
      "cleanroom": true
    }
  }
}