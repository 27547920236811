
import { IUploadService, UploadedFile} from "./iupload-service";
import { PresignedUrlResult } from "models/dsp/banner.dto";


export class MockUploadService implements IUploadService {
  uploadZipFolder(blob: Blob): Promise<UploadedFile[]>{
    throw new Error("Method not implemented.");
  }

  uploadFolder(files: File[]): Promise<UploadedFile[]> {
    throw new Error("Method not implemented.");
  }

  uploadFile(file: File): Promise<UploadedFile> {
    throw new Error("Method not implemented.");
  }
}
