import { AccountResponseDto } from "models/iam/account.dto";
import { IAccountService } from "./iaccount-service";

export class MockAccountService implements IAccountService {
  async getAccountById(id: number): Promise<AccountResponseDto> {
    return {
      id: id,
      accountId: '00000',
      accountName: 'Mock Account',
      status: 'active',
      enable_advertiser: true
    }
  }

  async getAccounts(): Promise<AccountResponseDto[]> {
    return [
      {
        id: 0,
        accountId: '00000',
        accountName: 'Mock Account',
        status: 'active',
        enable_advertiser: true
      },
      {
        id: 0,
        accountId: '00001',
        accountName: 'Mock Account 2',
        status: 'active',
        enable_advertiser: true
      },
      {
        id: 0,
        accountId: '00002',
        accountName: 'Mock Account 3',
        status: 'active',
        enable_advertiser: true
      }
    ]
  }
}