import { Source } from "models/dmp/source.dto";
import { ISourceService } from "./isource-service";

export class MockSourceService implements ISourceService {
  async getSurfsideAdvertisingSources(accountId?: string | undefined): Promise<Source[]> {
    return [{
      "id": 31372,
      "accountId": "00000",
      "accountRef": 1,
      "sourceId": "00170",
      "sourceName": "Custom-Campaign",
      "sourceData": {
        "type": "pixel-based",
        "sourceEndDate": "2024-11-14",
        "sourceStartDate": "2023-11-14",
        "activationPlatform": "surf"
      }
    },
    {
      "id": 42072,
      "accountId": "00000",
      "accountRef": 1,
      "sourceId": "3d648",
      "sourceName": "Awareness-Campaign",
      "sourceData": {
        "type": "pixel-based",
        "sourceEndDate": "2023-11-25",
        "sourceStartDate": "2023-10-25",
        "activationPlatform": "surf"
      }
    }
    ]
  }

  async getById(id: string): Promise<Source> {
    return {
      "id": 31372,
      "accountId": "00000",
      "accountRef": 1,
      "sourceId": id,
      "sourceName": "Custom-Campaign",
      "sourceData": {
        "type": "pixel-based",
        "sourceEndDate": "2024-11-14",
        "sourceStartDate": "2023-11-14",
        "activationPlatform": "surf"
      }
    }
  }
}