import { useState, useEffect } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useDeepCompareEffect } from 'hooks/deep-compare-effect'

const SURFSIDE_ENDPOINT =
  process.env.REACT_APP_SURFSIDE_SERVICES_ENDPOINT || 'http://localhost:4000/api'

const SURFSIDE_ENDPOINT_V2 = process.env.REACT_APP_SURFSIDE_SERVICES_ENDPOINT_V2 ??
  (window.location.hostname == 'localhost') ? 'http://localhost:3100/api' : SURFSIDE_ENDPOINT;



export const useSurfsideApi = ({ path, method, payload, dependencies, v2 = false }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState({})
  const [error, setError] = useState(null)
  const makeObj = { obj: true }
  //const [isCancelled, setIsCancelled] = useState(false)
  let isCancelled = false
  useDeepCompareEffect(() => {
    async function getData() {
      try {
        setIsLoading(true)
        const response = await axios({
          method,
          url: `${v2 ? SURFSIDE_ENDPOINT_V2 : SURFSIDE_ENDPOINT}/${path}`,
          headers: { 
            Authorization: `Bearer ${Cookies.get('auth0-jwt')}` ,
          },
          data: payload,
        })

        !isCancelled && setIsLoading(false)
        !isCancelled && setResult(response?.data)
      } catch (err) {
        setIsLoading(false)
        !isCancelled && setError(err)
      }
    }
    getData()
    return () => {
      //setIsCancelled(true)
      //isCancelled = true
    }
  }, [...dependencies, isCancelled, method, path, makeObj])

  return [isLoading, result.data, error, Object.keys(result).length > 0 ? result : null]
}

export const useNewSurfsideApiOnUpdate = ({ path, method, payload, dependencies, token }, isInitialMount) => {
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState({})
  const [error, setError] = useState(null)
  let isCancelled = false

  useDeepCompareEffect(() => {
    if (isInitialMount && isInitialMount.current) {
      isInitialMount.current = false
      setError(null)
    } else {
      async function getData() {
        try {
          setIsLoading(true)
          setError(null)
          setResult(null)
          const response = await axios({
            method,
            url: `http://localhost:3100/${path}`,
            headers: { 
              Authorization: `Bearer ${token || Cookies.get('auth0-jwt')}` ,
            },
            data: payload,
          })


          setError(null)
          !isCancelled && setIsLoading(false)
          !isCancelled && setResult(response.data)
        } catch (err) {
          setIsLoading(false)
          setResult()
          setError((err.response || {}).data || err)
        }
      }
      getData()
    }
    return () => {
      //setIsCancelled(true)
      isCancelled = true
    }
  }, [...dependencies])

  return [isLoading, (result || {}).data, error, Object.keys(result || {}).length > 0 ? result : null]
}



export const useSurfsideApiOnUpdate = ({ path, method, payload, dependencies = [], token, v2 = false }, isInitialMount) => {
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState({})
  const [error, setError] = useState(null)
  let isCancelled = false

  useDeepCompareEffect(() => {
    if (isInitialMount && isInitialMount.current) {
      isInitialMount.current = false
      setError(null)
    } else {
      async function getData() {
        try {
          setIsLoading(true)
          setError(null)
          setResult(null)
          const response = await axios({
            method,
            url: `${v2 ? SURFSIDE_ENDPOINT_V2 : SURFSIDE_ENDPOINT}/${path}`,
            headers: { 
              Authorization: `Bearer ${token || Cookies.get('auth0-jwt')}` ,
              
            },
            data: payload,
          })

          setError(null)
          !isCancelled && setIsLoading(false)
          !isCancelled && setResult(response.data)
        } catch (err) {
          setIsLoading(false)
          setResult()
          setError((err.response || {}).data || err)
        }
      }
      getData()
    }
    return () => {
      //setIsCancelled(true)
      isCancelled = true
    }
  }, [...dependencies])


  return [isLoading, (result || {}).data, error, Object.keys(result || {}).length > 0 ? result : null]
}




/*
import { useAsync } from 'react-async-hook';

const fetchStarwarsHero = async id =>
  (await fetch(
    `https://swapi.co/api/people/${id}/`,
  )).json();

const StarwarsHero = ({ id }) => {
  const asyncHero = useAsync(fetchStarwarsHero, [id]);
  return (
    <div>
      {asyncHero.loading && <div>Loading</div>}
      {asyncHero.error && (
        <div>Error: {asyncHero.error.message}</div>
      )}
      {asyncHero.result && (
        <div>
          <div>Success!</div>
          <div>Name: {asyncHero.result.name}</div>
        </div>
      )}
    </div>
  );
};*/
