import type { Campaign, CampaignDto } from "models/dsp/campaign.dto";
import { ICampaignService } from "./icampaign-service";
import { BudgetPeriod, BudgetType } from "models/dsp/budget-item.dto";

export class MockCampaignService implements ICampaignService {

  private _campaigns: Map<string, Campaign>
  constructor() {
    this._campaigns = new Map<string, Campaign>();

    this._campaigns.set('ecf0f1', {
      id: 'ecf0f1',
      name: 'Mock Campaign',
      accountId: '00000',
      advertiserId: "3",
      interval: [new Date('2022-02-21'), new Date('2023-02-21')],
      budgets: [{
        type: BudgetType.DOLLARS,
        period: BudgetPeriod.DAILY,
        amount: 100
      }, {
        type: BudgetType.IMPRESSIONS,
        period: BudgetPeriod.YEARLY,
        amount: 10000000
      }]
    });
  }

  async getCampaignById(id: string): Promise<Campaign | null> {
    console.log(id, this._campaigns);
    return this._campaigns.get(id) ?? {
      id,
      name: "Example Campaign",
      accountId: "00000",
      advertiserId: "3",
      interval: [new Date("2022-02-21"), new Date("2023-02-21")],
      budgets: [{
        type: BudgetType.DOLLARS,
        period: BudgetPeriod.DAILY,
        amount: 100
      }, {
        type: BudgetType.IMPRESSIONS,
        period: BudgetPeriod.YEARLY,
        amount: 10000000
      }]
    }
  }

  async createCampaign(campaign: Campaign): Promise<Campaign> {
    campaign.id = Math.random().toString(16).substring(6);
    this._campaigns.set(campaign.id, campaign);
    return campaign;
  }

  async updateCampaign(campaign: Campaign): Promise<Campaign> {
    if (!campaign.id) {
      throw new Error('Campaign id is required');
    }
    this._campaigns.set(campaign.id, campaign);

    console.log(this._campaigns);
    return campaign;
  }

  private periodToInt(period: BudgetPeriod): number {
    switch (period) {
      case BudgetPeriod.HOURLY:
        return 1;
      case BudgetPeriod.DAILY:
        return 24;
      case BudgetPeriod.WEEKLY:
        return 168
      case BudgetPeriod.MONTHLY:
        return 720;
      case BudgetPeriod.YEARLY:
        return 8760;
      case BudgetPeriod.TOTAL:
        return -1;
    }
  }

  private typeToInt(type: BudgetType): number {
    switch (type) {
      case BudgetType.DOLLARS:
        return 1;
      case BudgetType.IMPRESSIONS:
        return 2;
    }
  }
}
