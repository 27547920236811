export const AppOrSiteData=[
  {label: "App", value:"app"},
  {label: "Site", value:"site"},
  {label: "Both", value:"both"},
]

export const bannerGenerateType=[
  {label: "Upload", value:"upload"},
  {label: "Paste", value:"paste"},
]

export const nativeGenerateType=[
  {label: "Upload", value:"upload"},
  {label: "Url", value:"Url"},
]

export const videoGenerateType=[
  {label: "Upload", value:"upload"},
  {label: "VAST Tag", value:"paste"},
]

export const bannerCreativeSizes = [
  {
    label: '512x512 (1:1)',
    value: '1x1',
    crop: '512x512',
  },
  {
    label: '1600x400 (4:1)',
    value: '4x1',
    crop: '1600x400',
  },
  {
    label: '1600x267 (6:1)',
    value: '6x1',
    crop: '1600x267',
  },
  {
    label: '1600x200 (8:1)',
    value: '8x1',
    crop: '1600x200',
  },
  {
    label: '1600x160 (10:1)',
    value: '10x1',
    crop: '1600x160',
  },
  {
    label: '512x1024 (1:2)',
    value: '1x2',
    crop: '512x1024',
  },
  {
    label: '400x1600 (1:4)',
    value: '1x4',
    crop: '400x1600',
  },
  {
    label: '1000x1600 (10:16)',
    value: '10x16',
    crop: '1000x1600',
  },
  {
    label: '1600x900 (16:9)',
    value: '16x9',
    crop: '1600x900',
  },
  {
    label: '300x250',
    value: '300x250',
    crop: '300x250',
  },
  {
    label: '300x600',
    value: '300x600',
    crop: '300x600',
  },
  {
    label: '320x50',
    value: '320x50',
    crop: '320x50',
  },
  {
    label: '320x100',
    value: '320x100',
    crop: '320x100',
  },
  {
    label: '320x480',
    value: '320x480',
    crop: '320x480',
  },
  {
    label: '336x280',
    value: '336x280',
    crop: '336x280',
  },
  {
    label: '728x90',
    value: '728x90',
    crop: '728x90',
  },
  {
    label: '970x90',
    value: '970x90',
    crop: '970x90',
  },
  {
    label: '970x250',
    value: '970x250',
    crop: '970x250',
  },
  {
    label: '160x600',
    value: '160x600',
    crop: '160x600',
  },
]

export const nativeCreativeSizes = [
  {
    label: '1050x1050 (1:1)',
    value: '1x1',
    crop: '1050x1050',
  },
  {
    label: '1200x627 (1.91:1)',
    value: '1.91x1',
    crop: '1200x627',
  }
]

export const clickThroughUrl = 'https://rtb.surfside.io/pixel/exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}'
export const clickThroughUrlUTM = 'https://rtb.surfside.io/pixel/exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}?utm_source=surfside&utm_medium=display&utm_campaign={source_id}&utm_content={adid}]&utm_term={creative_id}&surf_imp={bid_id}'


export const clickThroughUrlCM = '{SURF_COMMERCE_URL}'