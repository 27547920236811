import { Advertiser } from "models/dsp/advertiser.dto";
import { IAdvertiserService } from "./iadvertiser-service";

export class MockAdvertiserService implements IAdvertiserService {

  private data: Advertiser[];

  constructor() {
    this.data = [];

    for(let i = 0; i < 100; i++){
      let id = Math.ceil(Math.random() * 0xffffffff);
      let advertiserId = Math.random().toString(16).substring(2, 6);

      const advertiser = {
        id: i,
        advertiserId: String(i),
        accountId: '00000',
        name: `Mock Advertiser ${i}`,
      };

      this.data.push(advertiser);
    }
  }
  
  async getAdvertiserByRowId(id: number): Promise<Advertiser | undefined> {
    return this.data.find((a) => a.id === id);
  }

  async updateAdvertiser(advertiser: Advertiser): Promise<Advertiser> {
    let existing = this.data.find((a) => a.id === advertiser.id);
    if (!existing) {
      throw new Error('Advertiser not found');
    }

    Object.assign(existing, advertiser);

    return advertiser;
  }

  async getAdvertiserById(id: string): Promise<Advertiser | undefined> {
    return this.data.find((a) => a.advertiserId === id);
  }

  async createAdvertiser(advertiser: Advertiser): Promise<Advertiser> {
    //generate 6 character hex ID:
    advertiser.id = Math.ceil(Math.random() * 0xffffffff);
    advertiser.advertiserId = Math.random().toString(16).substring(2, 6);
    this.data.push(advertiser);
    return advertiser;
  }

  async getAdvertisers(): Promise<Advertiser[]> {
    return this.data ? [...this.data.values()] : [];
  }
}