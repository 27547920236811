import _ from "lodash";
import { IRuleService, Rule } from "./irule-service";

export class MockRuleService implements IRuleService {

  private rules: Rule[];

  constructor() {
    this.rules = [];
    for (let i = 0; i < 200; i++) {
      this.rules.push({
        id: Math.floor((Math.random() * 0xffffffff)),
        name: `Rule ${i}`,
        sourceId: 'ecf0f1',
        accountId: '00000'
      });
    }
  }
  async getRules(): Promise<Rule[]> {
    return this.rules;
  }

  async getRulesBySourceId(): Promise<Rule[]> {
    return _.take(this.rules, 10); // 10 for now
  }
}