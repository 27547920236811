import { IAnalyticService } from './ianalytic-service'
import { ConnectedService } from 'services/connected-service'
import { ConnectedServiceResult } from 'services/connected-service-result'

import { AnalyticsQueryDto } from 'models/query/query.dto'

interface AnalyticResults extends ConnectedServiceResult<any[]> {}

export class ConnectedAnalyticService extends ConnectedService implements IAnalyticService {
  async queryAnalyticData(query: AnalyticsQueryDto): Promise<any> {
    const response = await this._post<AnalyticResults>('analytics/query', query)
    if (response.success === true && response.data !== undefined) {
      return response.data
    }
    throw new Error(response.message)
  }
}
