import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'

//import styles from "../../../components/LayoutComponents/TopBar/Logo/style.module.scss";


export default class Index extends PureComponent {
  render() {
    return (
      <div
        style={{
          minHeight: 'calc(100vh - 500px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Helmet title="New User"/>
        <div
          style={{
            maxWidth: '600px',
            backgroundColor: '#fff',
            padding: '80px 30px',
            margin: '100px auto',
            borderRadius: '10px',
            flex: '1',
          }}
        >
          <div>
            <div className='mb-5 justify-content-center'style={{
              marginBottom: 'rem(5)',
              textAlign: 'center' }}
             >
              <img style={{
                height: '120px' }}
                   src="/resources/images/logo/surfside-logo-black-small.svg" alt="Surfside logo" />
            </div>
            <h1 className="mb-2" style={{
              marginBottom: 'rem(5)',
              maxWidth: '600px',
              margin: '0 auto',
              fontSize: '48px',
              fontStyle: 'normal',
              fontWeight: '300',
              lineHeight: '56px',
              letterSpacing: '-0.02em',
              textAlign: 'center'}}
            >Welcome to Surfside!</h1>
            <p className="mb-5" style={{
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '300',
            lineHeight: '30px',
            letterSpacing: '0em',
            textAlign: 'center',}}
            >A customer success representative will be reaching out shortly to walk you through setting up your Surfside account, connecting your sources and viewing customer and campaign insights.</p>
            {/*<h1 className="font-size-80 mb-4 font-weight-bold">404 —</h1>*/}
            <div className='justify-content-center'style={{
              marginBottom: 'rem(5)',
              textAlign: 'center' }}
            >
              <a href="https://kb.surfside.io/" rel="noreferrer" target="_blank" className="btn" style={{
                color: '#fff',
                fontSize: '18px',
                textAlign: 'center',
                background: '#7B4AF4',
                borderRadius: '6px'}}>
                Access Knowledge Base
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
