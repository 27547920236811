import { ConnectedService } from "services/connected-service";
import { ICampaignService } from "./icampaign-service";
import { Campaign, CampaignDto } from "models/dsp/campaign.dto";
import { mapBudgetToDto, mapDtoToBudget } from "../helpers/budget-helpers";
import { ConnectedServiceResult } from "services/connected-service-result";
import moment from "moment";

interface CampaignResult extends ConnectedServiceResult<CampaignDto>{}

export class ConnectedCampaignService extends ConnectedService implements ICampaignService {

  constructor(url: string) {
    super(url);
  }

  private mapDtoToCampaign(dto: CampaignDto): Campaign {
    return {
      id: dto.id,
      accountId: dto.accountId,
      advertiserId: dto.advertiserId,
      name: dto.name,
      externalId: dto.externalId,
      interval: [moment(dto.startDate), moment(dto.endDate)],
      budgets: dto?.budgets?.map(mapDtoToBudget)
    }
  }

  private mapCampaignToDto(campaign: Campaign): CampaignDto {
    return {
      id: campaign.id,
      accountId: campaign.accountId,
      advertiserId: campaign.advertiserId,
      name: campaign.name,
      externalId: campaign.externalId,
      startDate: campaign.interval![0] as Date,
      endDate: campaign.interval![1] as Date,
      budgets: campaign.budgets.map(mapBudgetToDto)
    }
  }

  async getCampaignById(id: string): Promise<Campaign | null> {
    const result = await this._get<CampaignResult>(`dsp/campaigns/${id}`);
    if(result.success === true && result.data !== undefined){
      return this.mapDtoToCampaign(result.data);
    }
    throw new Error(result.message);
  }

  async getAllCampaigns(): Promise<Campaign[] | null> {
    const result = await this._get<any>(`dsp/campaigns`);
    if(result.success === true && result.data !== undefined){
      return result.data.map((c: any) => this.mapDtoToCampaign(c));
    }
    throw new Error(result.message);
  }

  async createCampaign(campaign: Campaign): Promise<Campaign> {
    const result = await this._post<CampaignResult>('dsp/campaigns', this.mapCampaignToDto(campaign));
    if(result.success === true && result.data !== undefined){
      return this.mapDtoToCampaign(result.data);
    }
    throw new Error(result.message);
  }

  async updateCampaign(campaign: Campaign): Promise<Campaign> {
    const result = await this._patch<CampaignResult>(`dsp/campaigns`, this.mapCampaignToDto(campaign));
    if(result.success === true && result.data !== undefined){
      return this.mapDtoToCampaign(result.data);
    }
    throw new Error(result.message);
  }
}