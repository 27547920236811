import { BudgetItem, BudgetItemDto, BudgetPeriod, BudgetType } from "models/dsp/budget-item.dto";

function periodToHours(period: BudgetPeriod): number | undefined {
  switch (period) {
    case BudgetPeriod.HOURLY:
      return 1;
    case BudgetPeriod.DAILY:
      return 24;
    case BudgetPeriod.WEEKLY:
      return 24 * 7;
    case BudgetPeriod.MONTHLY:
      return 24 * 30;
    case BudgetPeriod.YEARLY:
      return 24 * 365;
    case BudgetPeriod.TOTAL:
      return -1;
    default:
      return undefined;
  }
}

function hoursToPeriod(hours: number): BudgetPeriod | undefined {
  switch(hours) {
    case 1:
      return BudgetPeriod.HOURLY;
    case 24:
      return BudgetPeriod.DAILY;
    case 24 * 7:
      return BudgetPeriod.WEEKLY;
    case 24 * 30:
      return BudgetPeriod.MONTHLY;
    case 24 * 365:
      return BudgetPeriod.YEARLY;
    case -1:
      return BudgetPeriod.TOTAL;
    default:
      return undefined;
  }
}

export function mapBudgetToDto(budget: BudgetItem): BudgetItemDto {
  let period = periodToHours(budget.period);
  if(period === undefined){
    throw new Error('Invalid period');
  }

  let type = budget.type === BudgetType.DOLLARS ? 1 : 2;

  return {
    period,
    type,
    amount: typeof budget?.amount === 'number' ? budget.amount : parseInt(budget.amount)
  }
}

export function mapDtoToBudget(dto: BudgetItemDto): BudgetItem {
  let period = hoursToPeriod(dto.period);
  if(period === undefined){
    throw new Error('Invalid period');
  }

  let type: BudgetType | undefined;
  switch(dto.type){
    case 1:
      type = BudgetType.DOLLARS;
      break;
    case 2:
      type = BudgetType.IMPRESSIONS;
      break;
    default:
      throw new Error('Invalid type');
  }

  return {
    period,
    type,
    amount: dto.amount
  };
}
