import type { LineItem, LineItemDto } from "models/dsp/lineItem.dto";
import { IStrategyService} from "./istrategy-service";

export class MockStrategyService implements IStrategyService {

  private _strategies: Map<string, LineItem>

  constructor() {
    this._strategies = new Map<string, LineItem>();

    this._strategies.set('2a160', {
      id: '2a160',
      sourceId: "00170",
      name: "MOCK Strategy",
      budget: 121212,
      impressions: 1000,
      cost: 121212,
      interval: [new Date("2024-02-20T02:19:33.000Z"), new Date("2024-03-01T02:19:33.000Z")],
      accountId: "00000",
      createdAt: new Date(),
      updatedAt: new Date(),
      attributes: [
        { key: 'strategy', value: 'Contextual' },
        { key: 'campaign', value: 'MOCK Campaign' },
        { key: 'store', value: 'MOCK Store' },
        { key: 'market', value: 'MOCK Market' },
        { key: 'location', value: 'MOCK Location' }
      ]
    });
  }

  async getStrategies(): Promise<LineItem[]> {
    return Array.from(this._strategies.values());
  }

  async getStrategyById(id: string): Promise<LineItem | null> {
    return this._strategies.get(id) ?? null;
  }

  async createStrategy(strategy: LineItem): Promise<LineItem> {
    strategy.id = Math.random().toString(16).substring(6);
    this._strategies.set(strategy.id, strategy);
    return strategy;
  }

  async updateStrategy(strategy: LineItem): Promise<LineItem> {
    if (!strategy.id) {
      throw new Error('Strategy id is required');
    }
    this._strategies.set(strategy.id, strategy);

    console.log(this._strategies);
    return strategy;
  }

}
