// src/index.js

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { store, history, App } from "./App";
import { unregister } from "./serviceWorker";
import { Auth0Provider } from "./auth0";
import config from "./auth_config.json";
import configDev from "./auth_config_dev.json";
import configTest from "./auth_config_test.json"
import { AbilityProvider, } from './casl.js'
import { QBProvider } from './qb'
import { BreadcrumbProvider } from './Breadcrumb';
const _history = createBrowserHistory()
// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  _history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};


let currentConfig = config;

if (process?.env?.REACT_APP_SURFSIDE_ENV !== 'production'
  && process?.env?.REACT_APP_SURFSIDE_ENV !== 'demo'
  && process?.env?.REACT_APP_SURFSIDE_ENV !== 'beta'
  && process?.env?.REACT_APP_SURFSIDE_ENV !== 'test'
  
  ) {
  currentConfig = configDev;
}

if (process.env.REACT_APP_SURFSIDE_ENV == 'development') {
  currentConfig = configDev;
}

// if (process.env.REACT_APP_SURFSIDE_ENV == 'test') {
//   currentConfig = configTest;
// }

const authProviderSettings = {
  domain: currentConfig.domain,
  client_id: currentConfig.clientId,
  redirect_uri: window.location.origin,
  audience: currentConfig.audience,
  onRedirectCallback
};

if (window.location.hostname == 'localhost') {
  authProviderSettings.useRefreshTokens = true;
  authProviderSettings.cacheLocation = 'localstorage';
}

ReactDOM.render(
  <Auth0Provider
    {...authProviderSettings}
  >
    <AbilityProvider>
      <QBProvider>
        <BreadcrumbProvider>
          <App />
        </BreadcrumbProvider>
      </QBProvider>
    </AbilityProvider>
  </Auth0Provider>, document.getElementById("root")
);

unregister();
//serviceWorker.register()
export { store, history }
