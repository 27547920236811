import { ConnectedService } from "services/connected-service";
import { IAdvertiserService } from "./iadvertiser-service";
import { Advertiser } from "models/dsp/advertiser.dto";
import { ConnectedServiceResult } from "services/connected-service-result";

interface AdvertiserResults extends ConnectedServiceResult<Advertiser[]> { }
interface AdvertiserResult extends ConnectedServiceResult<Advertiser> { }

export class ConnectedAdvertiserService extends ConnectedService implements IAdvertiserService {

  constructor(url: string) {
    super(url)
  }

  async getAdvertiserById(id: string): Promise<Advertiser | undefined> {
    const result = await this._get<AdvertiserResult>(`dsp/advertisers/${id}`);
    if (result.success === true && result.data !== undefined) {
      return result.data;
    }
    throw new Error(result.message);
  }

  async createAdvertiser(advertiser: Advertiser): Promise<Advertiser> {
    const response = await this._post<AdvertiserResult>('dsp/advertisers', advertiser);
    if (response.success === true && response.data !== undefined) {
      return response.data;
    }
    throw new Error(response.message);
  }

  async updateAdvertiser(advertiser: Advertiser): Promise<Advertiser> {
    const response = await this._patch<AdvertiserResult>('dsp/advertisers', advertiser);
    if (response.success === true && response.data !== undefined) {
      return response.data;
    }
    throw new Error(response.message);
  }

  async getAdvertisers(): Promise<Advertiser[]> {
    const response = await this._get<AdvertiserResults>('dsp/advertisers');
    if (response.success === true && response.data !== undefined) {
      return response.data;
    }
    throw new Error(response.message);
  }
}