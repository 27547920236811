import React from 'react'

const HamburgerIcon = props => (
  <svg width={22} height={22} fill="none" {...props} viewBox="0 0 18 18" >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 .986h18V3.29H0V.986zm0 6.907h18v2.302H0V7.893zM11 14.8H0v2.302h11V14.8z"
      fill="#fff"
    />
  </svg>
)

export default HamburgerIcon
