import React, { useState, useEffect, useRef } from 'react';
import styles from './style.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, Tooltip } from 'antd';

const Breadcrumbs = () => {
  let location = useLocation();
  const breadcrumbRef = useRef(null); // Add ref for the breadcrumb wrapper
  const [breadcrumbState, setBreadcrumbState] = useState([{ title: 'Home', path: '/' }]);

  useEffect(() => {
    const currBreadcrumbs = JSON.parse(localStorage.getItem('breadcrumbHistory') || '{}');
    const hash = location.pathname;
    if (currBreadcrumbs && currBreadcrumbs[hash] && hash === '/home') {
      setBreadcrumbState([{ title: 'Home', path: '/' }]);
    } else if (currBreadcrumbs && currBreadcrumbs[hash]) {
      setBreadcrumbState(currBreadcrumbs[hash]);
    } else {
      setBreadcrumbState([{ title: 'Home', path: '/' }]);
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Apply styles directly when scrolled
        breadcrumbRef.current.style.paddingTop = '2px';
        breadcrumbRef.current.style.paddingBottom = '2px';
        breadcrumbRef.current.style.backgroundColor = '#FFF';
        breadcrumbRef.current.style.borderBottom = '1px solid #CCCCCC';
      } else {
        // Revert styles when not scrolled
        breadcrumbRef.current.style.paddingTop = ''; // Reset to default or specify your default value
        breadcrumbRef.current.style.backgroundColor = ''; // Reset to default
        breadcrumbRef.current.style.borderBottom = '';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getDisplayText = (value) => {
    return value.length > 1000 ? value.slice(0, 1000) + '...' : value;
  };

  const renderBreadcrumbs = (arr) => {
    return arr?.map((breadcrumb, index) => (
      <Tooltip title={breadcrumb.title.length > 25 ? breadcrumb.title : null} placement="bottom" key={breadcrumb.path}>
        <Breadcrumb.Item>
          <Link to={breadcrumb.path}>{getDisplayText(breadcrumb.title)}</Link>
        </Breadcrumb.Item>
      </Tooltip>
    ));
  };

  return (
    <div className={styles.breadcrumbWrapper} ref={breadcrumbRef}>
      <Breadcrumb>{renderBreadcrumbs(breadcrumbState)}</Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
