import { Source } from 'models/dmp/source.dto'
import { ISourceService } from './isource-service'
import { ConnectedService } from 'services/connected-service'
import { ConnectedServiceResult } from 'services/connected-service-result'

interface SourceResult extends ConnectedServiceResult<Source> { }
interface SourceResults extends ConnectedServiceResult<Source[]> { }

export class ConnectedSourceService extends ConnectedService implements ISourceService {
  constructor(url: string) {
    super(url)
  }

  async getById(id: string): Promise<Source> {
    const response = await this._get<SourceResult>(`sources/${id}`)

    if (response.success === true && response.data !== undefined) {
      return response.data
    }
    throw new Error(response.message)
  }

  async getSurfsideAdvertisingSources(accountId?: string | undefined): Promise<Source[]> {
    let sourceQuery = {
      type: 'source',
      filters: [
        {
          source: {
            sourceType: 'advertising',
            sourceData: {
              activationPlatform: 'surf',
            },
            accountId: accountId,
          },
        },
      ],
      fields: {
        source: [
          'id',
          'accountId',
          'accountRef',
          'sourceId',
          'sourceName',
          'advertiserId',
          'sourceData',
        ],
      },
    }
    const response = await this._post<SourceResults>('sources/advertising/pixel/query', sourceQuery)

    if (response.success === true && response.data !== undefined) {
      return response.data
    }
    throw new Error(response.message)
  }
}
