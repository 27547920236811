import { GetPublisherConfigurationDTO, PublisherConfig, PutPublisherConfigurationDTO } from "models/ssp/publisher.dto";
import { IPublisherService } from "./ipublisher-service";

export class MockPublisherService implements IPublisherService {

  private readonly publisherConfigs: Map<string, GetPublisherConfigurationDTO> = new Map<string, GetPublisherConfigurationDTO>();

  async getConfiguration(siteId: string): Promise<GetPublisherConfigurationDTO | null> {
    if(this.publisherConfigs.has(siteId)){
      return this.publisherConfigs.get(siteId)!;
    } else {
      return {
        "id": 12345,
        "accountId": "00000",
        "siteId": siteId,
        "geotargetingEnabled": true,
        "ipTargetingEnabled": true,
        "reauctionEnabled": true,
        "commerceMediaEnabled": true,
        "sampleSiteUrl": "https://ecomm.surfside.io/location/richmond-va/willow-lawn-weed/edibles?cartId=ecf0f1&search=cheesy%20puffs#details",
        "sampleProductData": {
          image: 'https://surf-creative-assets-test.s3.us-east-1.amazonaws.com/creatives/2cbd2.png',
          price: '$55',
          salePrice: '$30',
          thc: '200mg',
          cbd: '5mg',
          strain: 'Hybrid',
          name: "Flippin' hot cheese-o's",
          brandName: 'Surfside',
          productType: 'Edibles',
          details: 'These cayenne-dusted cannabis cheese puffs are the perfect snack for getting through a baby shower or your daily standup!',
          cta: 'Add to cart',
          clickthroughUrl: 'https://www.surfside.io/commerce-media',
          ext: {
            foo: 'bar'
          }
        },
        "reauctionIntervalMs": 30000,
        "productFeedEnabled": true,
        "clickthroughUrlTemplate": "https://localhost:8081/{{slug product.brand_name}}",
        "productCardTemplate": `
          <div style="max-width:300px; padding:10px"
                class="product-card">
            <img
              src="{{product.image}}"
              style="
                max-width: 400px;
                border-radius: 15px;
                -webkit-box-shadow: 0 0 9px 0 rgba(0,0,0,.23);
                -moz-box-shadow: 0 0 9px 0 rgba(0,0,0,.23);
                box-shadow: 0 0 9px 0 rgba(0,0,0,.23)"
              alt="{{product.name}}"
            >
            <h2 style="text-shadow:0 0 2px #adadad">{{coalesce catalog.name product.name}}</h2>
            <p style="font-size:22px">
              <strong>{{product.price}}</strong>
              {{#if product.salePrice}}
                <span style="padding-left:10px">Now only
                  <strong style="color:red">{{product.salePrice }}</strong>!!!
                </span>
              {{/if}}
              <p style="
                line-height: 1.75em;
                font-size:18px"
              >
                {{coalesce catalog.details product.details}}
              </p>
              <div style="text-align: center">
                <a
                  href="{{catalog.clickthrough_url}}"
                  target="_blank"
                >
                  <button style="
                    border-radius: 20px;
                    background: #1256b6;
                    background: linear-gradient(0deg, rgba(18,86,182,1) 0, rgba(0,104,255,1) 100%);
                    color: #fff;
                    border: none;
                    font-size: 22px;
                    padding: 10px 20px;
                    cursor: pointer;
                    margin-bottom: 20px;
                    -webkit-box-shadow: 0 0 9px 0 rgba(0,0,0,.23);
                    -moz-box-shadow: 0 0 9px 0 rgba(0,0,0,.23);
                    box-shadow: 0 0 9px 0 rgba(0,0,0,.23);
                    text-shadow:0 0 2px #000"
                  >
                    {{product.cta}}
                  </button>
                </a>
              </div>
            <div style="
              text-align: right"
            >
              Sponsored by {{product.brand_name}}
            </div>
          </div>`,
        "productCardStylesheet": ".foo { color: red; }",
        "carouselStylesheet": ""
      };
    }
  }

  async saveConfiguration(siteId: string, config: PutPublisherConfigurationDTO): Promise<void> {
    this.publisherConfigs.set(siteId, config);
  }
}
