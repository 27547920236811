import _ from 'lodash'
import qs from 'qs'
import * as createVast from 'vast-builder'
import { Banner } from 'models/dsp/banner.dto'
import { message } from 'antd'
import { VastMeta, Video, defaultConfigSchema } from 'models/dsp/video.dto'
import { Native } from 'models/dsp/native.dto'
import { BudgetItem, TotalBudgetItem } from 'models/dsp/budget-item.dto'
import {
  clickThroughUrl,
  clickThroughUrlCM,
  clickThroughUrlUTM,
} from 'components/SurfsideUIComponents/Dsp/Creative/creativeUtil'
let XMLParser = require('react-xml-parser')

export class CreativeUtilHelper {
  static checkCommerceMediaEnable(type: string, creative: any): boolean {
    switch (type) {
      case 'static':
      case 'richMedia':
      case 'video':
        return creative.clickThroughUrl === clickThroughUrlCM
      case 'native':
        const nativeCreative = creative as Native
        return nativeCreative.assets?.link === clickThroughUrlCM
      default:
        return false
    }
  }

  static checkUtmDecorators(type: string, creative: any): boolean {
    switch (type) {
      case 'static':
      case 'richMedia':
      case 'video':
        return CreativeUtilHelper.checkClickThroughUrlUTMEnabled(
          creative.clickThroughUrl || '',
        )

      case 'native':
        const nativeCreative = creative as Native
        return CreativeUtilHelper.checkClickThroughUrlUTMEnabled(nativeCreative.assets?.link || '')
      default:
        return false
    }
  }
  // {type: 'Dollars', amount: 10000}
  static calculateAutoBudget(totalBudget: TotalBudgetItem, days: number): any[] | undefined {

    if (!totalBudget?.amount) {
      return undefined
    }
    if (totalBudget?.type === 'Dollars') {
      return [
        {
          period: 'Daily',
          type: 'Dollars',
          amount: +(+totalBudget?.amount / days).toFixed(2),
        },
        {
          period: 'Hourly',
          type: 'Dollars',
          amount: +(+totalBudget?.amount / days / 24).toFixed(2),
        },
      ]
    } else if (totalBudget?.type === 'Impressions') {
      return [
        {
          period: 'Daily',
          type: 'Impressions',
          amount: Math.round(+totalBudget?.amount / days),
        },
        {
          period: 'Hourly',
          type: 'Impressions',
          amount: Math.round(+totalBudget?.amount / days / 24),
        },
      ]
    }

    return undefined
  }

  static getDatasetAttribute(
    contentType: 'banner' | 'video' | 'native',
    name: string,
    str: string,
  ): string {
    let _template: string = ''
    if (contentType === 'banner') _template = str
    if (contentType === 'video') {
      let xml = new XMLParser().parseFromString(str)
      let ext = (xml && xml.getElementsByTagName('CreativeExtensions')[0]?.value) || ''
      _template = ext
    }

    const doc = document.createElement('div')
    doc.innerHTML = _template.trim()
    const brandInfo = doc.querySelector('#surf-product-info') as HTMLDivElement
    if (brandInfo) {
      return brandInfo.dataset[name] || ''
    } else {
      return ''
    }
  }

  static checkAttribute(element: HTMLDivElement, attr: string | undefined, name: string) {
    const dataName = `data-${name.toLowerCase()}`
    if (attr) {
      element.setAttribute(dataName, attr)
    } else {
      element.removeAttribute(dataName)
    }
  }

  static convertHtmlTemplateToIframeSrc(value: string) {
    const doc = document.createElement('div')
    doc.innerHTML = value
    let productInfo = doc.querySelector('#surf-product-info') as HTMLDivElement
    if (productInfo) productInfo.remove()
    return doc.innerHTML
      .replace('{rtb_pixel}', '')
      .replace('{rtb_click}', '')
      .replace('{/rtb_click}', '')
  }

  static async readFile(file: File): Promise<string> {
    return new Promise((res, rej) => {
      const reader = new FileReader()
      reader.onload = async (e: { target: any }) => {
        let blob: BlobPart = new Blob([e.target.result], { type: file.type })
        res(URL.createObjectURL(blob))
      }
      reader.readAsArrayBuffer(file)
    })
  }

  static generateQuery(vastVideoOutgoingFile: string) {
    return vastVideoOutgoingFile && vastVideoOutgoingFile.replaceAll('\n', '')
      ? `https://vasttester.iabtechlab.com/#/run?${CreativeUtilHelper.stringifyConfig({
        ...defaultConfigSchema,
        vastUrl: CreativeUtilHelper.toDataUri(vastVideoOutgoingFile),
      })}`
      : ''
  }

  static createVastTag(url: string, vastMeta: VastMeta, cm: string): string {
    if (_.isEmpty(vastMeta)) {
      return ''
    } else if (Boolean(cm)) {
      const vast3 = createVast.v3()
      const duration = CreativeUtilHelper.formatDuration(vastMeta.vastVideoDuration)
      vast3
        .attachAd({ id: 'preroll' })
        .attachInLine()
        .addImpression(clickThroughUrl, { id: 'impression' })
        .addAdSystem('2.0')
        .addAdTitle('Title')
        .attachCreatives()
        .attachCreative()
        .dangerouslyAttachCustomTag('CreativeExtensions', cm)
        .back()
        .attachCreative()
        .attachCompanionAds()
        .back()
        .attachCreative()
        .attachLinear()
        .attachTrackingEvents()
        .addTracking(
          'https://rtb.surfside.io/track?et=start&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'start' },
        )
        .addTracking(
          'https://rtb.surfside.io/track?et=first&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'firstQuartile' },
        )
        .addTracking(
          'https://rtb.surfside.io/track?et=second&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'midpoint' },
        )
        .addTracking(
          'https://rtb.surfside.io/track?et=third&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'thirdQuartile' },
        )
        .addTracking(
          'https://rtb.surfside.io/track?et=complete&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'complete' },
        )
        .addTracking(
          'https://rtb.surfside.io/track?et=stop&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'stop' },
        )
        .and()
        .addDuration(duration)
        .attachMediaFiles()
        .attachMediaFile(url, {
          delivery: 'progressive',
          type: vastMeta.mimeType,
          width: vastMeta.vastVideoWidth,
          height: vastMeta.vastVideoHeight,
        })
        .back()
        .attachVideoClicks()
        .attachClickThrough('{SURF_COMMERCE_URL}', { id: 'scanscout' })

      return vast3.toXml()
    } else {
      const vast3 = createVast.v3()
      const duration = CreativeUtilHelper.formatDuration(vastMeta.vastVideoDuration)

      vast3
        .attachAd({ id: 'preroll' })
        .attachInLine()
        .addImpression(
          'https://rtb.surfside.io/pixel/exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { id: 'impression' },
        )
        .addAdSystem('2.0')
        .addAdTitle('Title')
        .attachCreatives()
        .attachCreative()
        .attachCompanionAds()
        .back()
        .attachCreative()
        .attachLinear()
        .attachTrackingEvents()
        .addTracking(
          'https://rtb.surfside.io/track?et=start&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'start' },
        )
        .addTracking(
          'https://rtb.surfside.io/track?et=first&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'firstQuartile' },
        )
        .addTracking(
          'https://rtb.surfside.io/track?et=second&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'midpoint' },
        )
        .addTracking(
          'https://rtb.surfside.io/track?et=third&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'thirdQuartile' },
        )
        .addTracking(
          'https://rtb.surfside.io/track?et=complete&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'complete' },
        )
        .addTracking(
          'https://rtb.surfside.io/track?et=stop&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&ua={ua_enc}&deal_id={deal_id}',
          { event: 'stop' },
        )
        .and()
        .addDuration(duration)
        .attachMediaFiles()
        .attachMediaFile(url, {
          delivery: 'progressive',
          type: vastMeta.mimeType,
          width: vastMeta.vastVideoWidth,
          height: vastMeta.vastVideoHeight,
        })
        .back()
        .attachVideoClicks()
        .attachClickThrough(
          'https://rtb.surfside.io/callback?target=redirect&exchange={exchange}&ad_id={ad_id}&account_id={account_id}&source_id={source_id}&line_item_id={line_item_id}&target_group_id={target_group_id}&creative_id={creative_id}&price=${AUCTION_PRICE}&bid_id={bid_id}&type={bid_type}&pub_id={pub_id}&pub_name={pub_name}&pub_domain={pub_domain}&pub_bundle={pub_bundle}&creative_size={creative_size}&deviceid={deviceid}&deviceid_hash={deviceid_hash}&lat={lat}&lon={lon}&make={make}&model={model}&os={os}&osv={osv}&ip={ip}&isp={isp}&deal_id={deal_id}&ua={ua_enc}&url=_REDIRECT_URL_',
          { id: 'scanscout' },
        )

      return vast3.toXml()
    }
  }

  private static secondsFromDuration(duration: string) {
    let parts = duration.split(':')
    let seconds = 0
    for (let i = parts.pop(), multiplier = 1; i; i = parts.pop()) {
      seconds += parseInt(i) * multiplier
      multiplier *= 60
    }
    return seconds
  }

  static setVideoMetaVastMetaFromUrl(url: string, type: string): Promise<{ vastMeta: VastMeta }> {
    return new Promise((res, rej) => {
      let video = document.createElement('video') // create video element
      video.preload = 'metadata' // preload setting
      video.addEventListener('loadedmetadata', function () {
        const vastMeta = {
          vastVideoWidth: video.videoWidth,
          vastVideoHeight: video.videoHeight,
          vastVideoLinearity: 1,
          vastVideoDuration: Math.round(video.duration),
          vastVideoType: 'vast_3_0',
          mimeType: type,
          vastVideoProtocol: 3,
        }
        res({ vastMeta })
      })
      video.src = url
    })
  }

  static setFormWithMeta(form: any, meta: VastMeta, vastVideoOutgoingFile: string, iurl: string) {
    form.setFieldsValue({
      ...meta,
      vastVideoOutgoingFile,
      iurl,
    })
  }

  static clearMeta(form: any) {
    form.setFieldsValue({
      vastVideoWidth: '',
      vastVideoHeight: '',
      vastVideoLinearity: '',
      vastVideoDuration: '',
      vastVideoType: '',
      mimeType: '',
      vastVideoOutgoingFile: '',
    })
  }

  static clearUploadHtml(form: any) {
    form.setFieldsValue({
      file: '',
      imageUrl: '',
      clickThroughUrl: '',
      htmlTemplate: '',
      contentType: '',
      size: '',
    })
  }

  static clearNativeAssets(form: any) {
    form.setFieldsValue({
      assets: {
        image: {
          width: '',
          height: '',
          imageUrl: '',
          mageUrlGenerateType: 'upload',
        },

        title: '',
        link: '',
        videoVastTag: '',
        data: [{ type: '', value: '' }],
        clickTrackerUrls: [{ type: '', value: '' }],
        size: '',
        contentType: 'image',
      },
    })
  }

  static allObjectsEmptyStrings(arrayOfObjects: {}[]) {
    // Handle non-array input
    if (!Array.isArray(arrayOfObjects)) {
      return false
    }

    // Check if all objects have all empty strings using every and some
    return arrayOfObjects.every((obj) => Object.values(obj).every((val) => val === ''))
  }

  static checkVideoCMEnabled(vastTag: string): boolean {
    if (!vastTag) return false
    let xml = new XMLParser().parseFromString(vastTag)
    let extNode = xml && xml.getElementsByTagName('CreativeExtensions')
    return extNode.length > 0
  }



  static isValidUrl(string: string) {
    let url
    if (!string) return false
    if (string === clickThroughUrlCM) return true
    try {
      url = new URL(string)
    } catch (_) {
      return false
    }
    return url.protocol === 'https:' || url.protocol === 'http:'
  }

  static updateVideotagClickThroughUrl(vastXmlString: string, clickThroughUrl: string) {
    if (!vastXmlString) return vastXmlString
    const clickThroughRegex = /<ClickThrough id="scanscout"><!\[CDATA\[(.*?)\]\]><\/ClickThrough>/s
    const matchclickThrough = vastXmlString.match(clickThroughRegex)
    if (matchclickThrough) {
      return vastXmlString.replace(
        clickThroughRegex,
        `<ClickThrough id="scanscout"><![CDATA[${clickThroughUrl}]]><\/ClickThrough>`,
      )
    }
    return vastXmlString
  }

  static getVideotagClickThroughUrl(vastXmlString: string) {
    if (!vastXmlString) return vastXmlString
    const clickThroughRegex = /<ClickThrough id="scanscout"><!\[CDATA\[(.*?)\]\]><\/ClickThrough>/s
    const matchclickThrough = vastXmlString.match(clickThroughRegex)
    if (matchclickThrough) {
      const clickThroughUrl = matchclickThrough[1]
      return clickThroughUrl
    }
    return ''
  }

  static checkClickThroughUrlUTMEnabled(clickThroughUrl: string): boolean {
    const expectedParams = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'surf_imp',
    ]

    try {
      const url = new URL(clickThroughUrl)
      return expectedParams.every((e) => url.searchParams.has(e))
    } catch (e) {
      return false
    }
  }

  static buildUrlWithSearchParams(baseUrl: string) {
    const expectedParams = {
      utm_source: 'surfside',
      utm_medium: 'display',
      utm_campaign: '{source_id}',
      utm_content: '{adid}',
      utm_term: '{creative_id}',
      surf_imp: '{bid_id}',
    }

    try {
      const url = new URL(baseUrl)
      for (let [key, value] of Object.entries(expectedParams)) {
        if (!url.searchParams.has(key)) {
          url.searchParams.append(key, value)
        }
      }
      return url.toString()
    } catch (e) {
      return baseUrl
    }
  }

  static removeUtmParams(baseUrl: string) {
    const expectedParams = {
      utm_source: 'surfside',
      utm_medium: 'display',
      utm_campaign: '{source_id}',
      utm_content: '{adid}',
      utm_term: '{creative_id}',
      surf_imp: '{bid_id}',
    }

    try {
      const url = new URL(baseUrl)
      for (let [key, value] of Object.entries(expectedParams)) {
        if (url.searchParams.has(key)) {
          url.searchParams.delete(key, value)
        }
      }
      return url.toString()
    } catch (e) {
      return baseUrl
    }
  }

  static setVastMetaFromVastTag(value: string, setErrors: (value: any) => void, form: any) {
    try {
      let vastMeta = {} as VastMeta
      let xml = new XMLParser().parseFromString(value)
      let linearNode = xml && xml.getElementsByTagName('Linear')
      let vastNode = xml && xml.getElementsByTagName('VAST')
      let durationNode = xml && xml.getElementsByTagName('Duration')
      let mediaFileNode = xml && xml.getElementsByTagName('mediaFile')
      let iurl

      if (xml) {
        if (!_.isEmpty(linearNode)) {
          vastMeta = { ...vastMeta, vastVideoLinearity: 1 }
        } else {
          setErrors((prev: any) => ({
            ...prev,
            linearTagMissingError: 'Vast Template must include <Linear> Tag',
          }))
        }

        if (!_.isEmpty(vastNode)) {
          if (!_.isEmpty(vastNode[0].attributes)) {
            let version = vastNode[0].attributes.version
            // setVastMeta((prev) => ({ ...prev, vast_video_type: `vast_${version[0]}_0` }))
            vastMeta = {
              ...vastMeta,
              vastVideoType: `vast_${version[0]}_0`,
              vastVideoProtocol: parseInt(version[0]),
            }
          } else {
            setErrors((prev: any) => ({
              ...prev,
              vastVersionMissingError: 'Vast Template <Vast> Tag must have version attribute',
            }))
          }
        } else {
          // let { vast_video_type, ...meta } = vastMeta as VastMeta
          // setVastMeta(meta)
          // let { vastVersionMissingError, ...errs } = errors as AlertObject
          setErrors((prev: any) => ({
            ...prev,
            vastTagMissingError: 'Vast Template must include <vast> tag',
          }))
        }

        if (!_.isEmpty(durationNode)) {
          if (durationNode[0].value) {
            let duration = durationNode[0].value
            let array = duration
              .split(':')
              .map((t: string) => parseInt(t))
              .reverse()
            const reducer = (previousValue: number, currentValue: number, index: number) =>
              previousValue + 60 * index * currentValue
            // setVastMeta((prev) => ({ ...prev, vast_video_duration: array.reduce(reducer) }))
            vastMeta = { ...vastMeta, vastVideoDuration: array.reduce(reducer) }
          } else {
            setErrors((prev: any) => ({
              ...prev,
              durationValueMissingError: 'Vast Template <Duration> Tag must have value',
            }))
          }
        } else {
          setErrors((prev: any) => ({
            ...prev,
            duationTagMissingError: 'Vast Template must include <Duration> Tag',
          }))
        }

        if (!_.isEmpty(mediaFileNode)) {
          const { height, width, type } = mediaFileNode[0].attributes
          height
            ? (vastMeta = { ...vastMeta, vastVideoHeight: parseInt(height) })
            : setErrors((prev: any) => ({
              ...prev,
              mediaFileHeightAttributeMissingError:
                'Vast Template Media file tag must have video height attribute',
            }))
          width
            ? (vastMeta = { ...vastMeta, vastVideoWidth: parseInt(width) })
            : setErrors((prev: any) => ({
              ...prev,
              mediaFileWidthAttributeMissingError:
                'Vast Template Media file tag must have video width attribute',
            }))
          type
            ? (vastMeta = { ...vastMeta, mimeType: CreativeUtilHelper.formatVideoType(type) })
            : setErrors((prev: any) => ({
              ...prev,
              mediaFileTypeAttributeMissingError:
                'Vast Template Media file tag must have video type attribute',
            }))

          if (mediaFileNode[0].value) {
            iurl = mediaFileNode[0].value.replace('>', '').trim()
            // let ext = url.split(/[#?]/)[0].split('.').pop().trim()
            // setVideoMetaVastMetaFromUrl(url, `v_${ext}`)
          } else {
            setErrors((prev: any) => ({
              ...prev,
              mediaFileValueMissingError: 'Vast Template <MediaFile> tag must have value',
            }))
          }
        } else {
          setErrors((prev: any) => ({
            ...prev,
            mediaFileMissingError: 'Vast Template must include <mediaFile> Tag',
          }))
        }

        CreativeUtilHelper.setFormWithMeta(form, vastMeta, value, iurl)
      }
    } catch {
      setErrors({ typeError: "Cannot read properties of undefined (reading 'value')" })
    }
  }

  static updateCreativeExtensions(vastXmlString: string, newContent: string) {
    if (!vastXmlString) return vastXmlString
    const ExtensionRegex = /<CreativeExtensions><!\[CDATA\[(.*?)\]\]><\/CreativeExtensions>/s
    // Find the existing content within <CreativeExtensions>
    const matchExtension = vastXmlString.match(ExtensionRegex)

    if (newContent) {
      if (matchExtension) {
        return vastXmlString.replace(
          ExtensionRegex,
          `<CreativeExtensions><![CDATA[${newContent}]]></CreativeExtensions>`,
        )
      } else {
        const creativesStart = vastXmlString.indexOf('<Creatives>')
        return (
          vastXmlString.slice(0, creativesStart + 11) + // Insert after "<Creatives>"
          `\n<CreativeExtensions><![CDATA[${newContent}]]></CreativeExtensions>` +
          vastXmlString.slice(creativesStart + 11)
        )
      }
    } else {
      return vastXmlString.replace(ExtensionRegex, '')
    }
  }

  private static toDataUri(value: string) {
    return value.trim().charAt(0) === '<' ? 'data:text/xml;base64,' + btoa(value) : value
  }

  private static stringifyConfig(config: any) {
    return config == null ? '' : qs.stringify(config)
  }

  private static formatVideoType(type: string) {
    const [t, mime] = type.split('/')
    return t[0].toLowerCase() + '_' + mime.replace('-', '').toLowerCase()
  }

  private static formatDuration(time: number | undefined) {
    if (!time) return ''
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600)
    var mins = ~~((time % 3600) / 60)
    var secs = ~~time % 60

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = ''
    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
    }
    ret += '' + String(mins).padStart(2, '0') + ':' + (secs < 10 ? '0' : '')
    ret += '' + secs
    return ret
  }

  private static dataURLtoBlob(dataurl: string): Blob {
    let arr = dataurl.split(',')
    let mime = arr[0].match(/:(.*?);/)?.[1]
    if (mime === undefined) {
      console.log('There is no mime')
    }
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  private static async getCroppedImg(
    image: CanvasImageSource,
    fileType: string,
    cropHeight: string,
    cropWidth: string,
  ): Promise<Blob> {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')!

    canvas.width = +cropWidth
    canvas.height = +cropHeight

    ctx.drawImage(image, 0, 0, +cropWidth, +cropHeight)

    let dataUrl = canvas.toDataURL(fileType)
    let blobData = CreativeUtilHelper.dataURLtoBlob(dataUrl)

    // Return cropped img as a blob
    return blobData
  }

  private static async readImgAsDataUrl(file: Blob): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.readAsDataURL(file)
    })
  }

  private static async setImage(dataUrl: string): Promise<CanvasImageSource> {
    return new Promise((resolve) => {
      let image = new Image()
      image.onload = () => {
        resolve(image)
      }
      image.src = dataUrl
    })
  }

  static async processCroppedImage(
    file: File,
    cropHeight: string,
    cropWidth: string,
  ): Promise<Blob> {
    const dataUrl = await CreativeUtilHelper.readImgAsDataUrl(file)
    const img = await CreativeUtilHelper.setImage(dataUrl)
    const blobData = await CreativeUtilHelper.getCroppedImg(img, file.type, cropHeight, cropWidth)
    return blobData
  }

  static successMessage(content: string) {
    message.success({
      content: content,
      style: {
        position: 'absolute',
        top: '20px',
        right: '20px',
      },
    })
  }

  static errorMessage(content: string) {
    message.error({
      content: content,
      style: {
        position: 'absolute',
        top: '20px',
        right: '20px',
      },
    })
  }
}
