import { ConnectedService } from 'services/connected-service'
import { IVideoService } from './ivideo-service'
import { VastMeta, Video, VideosDto } from 'models/dsp/video.dto'
import _ from 'lodash'
import { ConnectedServiceResult } from 'services/connected-service-result'
import moment from 'moment'

interface VideoResult extends ConnectedServiceResult<VideosDto> { }

export class ConnectedVideoService extends ConnectedService implements IVideoService {
  constructor(url: string) {
    super(url)
  }

  async create(video: Video): Promise<Video> {
    let videoDto = this.mapVideoToDto(video)
    const result = await this._post<VideoResult>('dsp/videos', videoDto)
    if (result.success === true && result.data !== undefined) {
      return this.mapDtoToVideo(result.data)
    }
    throw new Error(result.message)
  }

  async update(video: Video): Promise<Video> {
    let { id, ...rest } = video
    const result = await this._patch<VideoResult>(`dsp/videos/${id}`, this.mapVideoToDto(rest))
    if (result.success === true && result.data !== undefined) {
      return this.mapDtoToVideo(result.data)
    }
    throw new Error(result.message)
  }

  async getById(id: number): Promise<Video> {
    const result = await this._get<VideoResult>(`dsp/videos/${id}`)
    if (result.success === true && result.data !== undefined) {
      return this.mapDtoToVideo(result.data)
    }
    throw new Error(result.message)
  }

  async getAllVideos(): Promise<Video[]> {
    const result = await this._get<any>(`dsp/videos`)
    if (result.success === true && result.data !== undefined) {
      return result.data?.map((d: VideosDto) => this.mapDtoToVideo(d))
    }
    throw new Error(result.message)
  }

  setVideoMetaVastMetaFromUrl(url: string, type: string): Promise<{ vastMeta: VastMeta }> {
    return new Promise((res, rej) => {
      let video = document.createElement('video') // create video element
      video.preload = 'metadata' // preload setting
      video.addEventListener('loadedmetadata', function () {
        const vastMeta = {
          vastVideoWidth: video.videoWidth,
          vastVideoHeight: video.videoHeight,
          vastVideoLinearity: 1,
          vastVideoDuration: Math.round(video.duration),
          vastVideoType: 'vast_3_0',
          mimeType: type,
          vastVideoProtocol: 3,
        }
        res({ vastMeta })
      })
      video.src = url
    })
  }
  private mapVideoToDto(video: Video): VideosDto | undefined {
    const { ruleGroups } = video
    return {
      id: video.id,
      bidEcpm: String(video.bidEcpm),
      name: video.name,
      dealsOptions: video.dealsOptions,
      deals: video.deals,
      sourceId: video.sourceId,
      accountId: video.accountId,
      type: 'video',
      intervalStart: video.interval[0] as Date,
      intervalEnd: video.interval[1] as Date,
      vastVideoWidth: video.vastVideoWidth,
      vastVideoHeight: video.vastVideoHeight,
      vastVideoLinerarity: video.vastVideoLinearity,
      vastVideoLinearity: video.vastVideoLinearity,
      cur: 'USD',
      vastVideoDuration: video.vastVideoDuration,
      vastVideoType: video.vastVideoType,
      vastVideoProtocol: video.vastVideoProtocol,
      vastVideoOutgoingFile: video.vastVideoOutgoingFile,
      mimeType: video.mimeType,
      ruleGroups: (ruleGroups && ruleGroups?.operator && ruleGroups.values.filter((r: string | any[])=>r.length!==0).length!==0)
      ? {
        operator: ruleGroups?.operator,
        values: ruleGroups?.values.filter((r: string | any[])=>r.length!==0).map((v: any[]) => v.filter((i) => i !== null && i !== undefined)),
      }
      : undefined,
      attr: video.attributes?.map((a) => +a),
      siteOrApp: video.siteOrApp,
      categories: video.categories,
      meta: { file: video.file?.file, iurl: video.iurl },
      tags: video.tags,
      clickthroughUrl:video.clickThroughUrl
    }
  }

  private mapDtoToVideo(dto: VideosDto): Video {
    return {
      id: dto.id,
      attributes: dto.attr?.map((a) => a.toString()),
      bidEcpm: dto.bidEcpm,
      name: dto.name,
      dealsOptions: dto.dealsOptions,
      deals: dto.deals,
      sourceId: dto.sourceId,
      accountId: dto.accountId,
      tags: Array.from(new Set(dto.tags)),
      interval: [moment(dto.intervalStart), moment(dto.intervalEnd)],
      categories: dto.categories,
      file: dto.meta?.file,
      siteOrApp: dto.siteOrApp,
      creativeType: 'video',
      contentType: 'video',
      iurl: dto.meta?.iurl,
      vastTagGeneratorType: "paste",
      ruleGroups: dto.ruleGroups,
      vastVideoWidth: dto.vastVideoWidth,
      vastVideoHeight: dto.vastVideoHeight,
      vastVideoLinearity: dto.vastVideoLinerarity ||dto.vastVideoLinearity,
      vastVideoDuration: dto.vastVideoDuration,
      vastVideoType: dto.vastVideoType,
      vastVideoProtocol: dto.vastVideoProtocol,
      vastVideoOutgoingFile: dto.vastVideoOutgoingFile,
      mimeType: dto.mimeType,
      clickThroughUrl:dto.clickthroughUrl

    }
  }
}
