import { ConnectedService } from 'services/connected-service'
import { IBannerService } from './ibanner-service'
import { Banner, BannerDto, creativeSizes } from 'models/dsp/banner.dto'
import { ConnectedServiceResult } from 'services/connected-service-result'
import moment from 'moment'

interface BannerResult extends ConnectedServiceResult<BannerDto> { }

const reader = new FileReader()

export class ConnectedBannerService extends ConnectedService implements IBannerService {
  async create(banner: Banner): Promise<Banner> {
    let bannerDto = this.mapBannerToDto(banner)
    const result = await this._post<BannerResult>('dsp/banners', bannerDto)
    if (result.success === true && result.data !== undefined) {
      return this.mapDtoToBanner(result.data)
    }
    throw new Error(result.message)
  }

  async update(banner: Banner): Promise<Banner> {
    let { id, ...rest } = banner
    let bannerDto = this.mapBannerToDto(rest)
    const result = await this._put<BannerResult>(`dsp/banners/${id}`, bannerDto)
    if (result.success === true && result.data !== undefined) {
        return this.mapDtoToBanner(result.data)
    }
    throw new Error(result.message)
  }

  async getById(id: number): Promise<Banner | null> {
    const result = await this._get<BannerResult>(`dsp/banners/${id}`)
    if (result.success === true && result.data !== undefined) {
      return this.mapDtoToBanner(result.data)
    }
    throw new Error(result.message)
  }

  async getAllBanners(): Promise<Banner[] | null> {
    const result = await this._get<any>(`dsp/banners`)
    if (result.success === true && result.data !== undefined) {
      return result.data?.map((d: BannerDto) => this.mapDtoToBanner(d))
    }
    throw new Error(result.message)
  }

  checkCommerceMediaEnable(html: string) {
    return html.includes('surf-product-info')
  }

  getCreativeDropdownType(type: string | undefined) {
    switch (type) {
      case 'image/png':
      case 'image/jpeg':
      case 'image/gif':
        return 'static'
      case 'text/html':
        return 'richMedia'
      default:
        return 'richMedia'
    }
  }

  getCreateHtmlMethod(file: string | undefined) {
    return file ? 'upload' : 'paste'
  }

  private mapDtoToBanner(dto: BannerDto): Banner {
    const { intervalStart, intervalEnd, width, height, ruleGroups, meta, deals } = dto

    let _size = creativeSizes.find((c: any) => c.value === `${width}x${height}`)!
    return {
      id: dto.id,
      attributes: dto.attributes?.map((a) => String(a)),
      bidEcpm: dto.bidEcpm,
      contentType: dto.contentType,
      imageUrl: dto.imageUrl,
      htmlTemplate: dto.htmlTemplate,
      name: dto.name,
      dealsOptions: dto.dealsOptions,
      deals: dto.deals,
      sourceId: dto.sourceId,
      accountId: dto.accountId,
      interval: [moment(intervalStart), moment(intervalEnd)],
      size: _size,
      ruleGroups: ruleGroups,
      categories: meta?.categories,
      clickThroughUrl: meta?.clickThroughUrl,
      siteOrApp: meta?.siteOrApp,
      file: meta?.file,
      creativeType: this.getCreativeDropdownType(dto.contentType),
      htmlGeneratorType: 'paste',
      tags: Array.from(new Set(dto.tags)),
    }
  }

  private mapBannerToDto(banner: Banner): BannerDto {
    const { interval, categories, clickThroughUrl, siteOrApp, file, size='', ruleGroups, imageUrl } =
      banner

    let _width = typeof size === 'string' ? +JSON.parse(size).value.split('x')[0] : +size.value.split('x')[0] 
    let _height =typeof size === 'string' ? +JSON.parse(size).value.split('x')[1] : +size.value.split('x')[1] 


    let _meta = {
      categories: categories,
      clickThroughUrl: clickThroughUrl,
      siteOrApp: siteOrApp,
      file: file,
      imageUrl: imageUrl,
    }

    return {
      id: banner.id,
      meta: _meta,
      attributes: banner.attributes,
      bidEcpm: Number(banner.bidEcpm),
      contentType: banner.contentType,
      imageUrl: banner.imageUrl,
      htmlTemplate: banner.htmlTemplate,
      name: banner.name,
      dealsOptions: banner.dealsOptions,
      deals: banner.deals,
      sourceId: banner.sourceId,
      ruleGroups: (ruleGroups && ruleGroups?.operator && ruleGroups.values.filter(r=>r.length!==0).length!==0)
        ? {
          operator: ruleGroups?.operator,
          values: ruleGroups?.values.filter(r=>r.length!==0).map((v) => v.filter((i) => i !== null && i !== undefined)),
        }
        : undefined,
      tags: banner.tags,
      accountId: banner.accountId,
      width: _width,
      height: _height,
      intervalStart: interval?.[0] as Date,
      intervalEnd: interval?.[1] as Date,
    }
  }
}
