
// import { IVideoService } from "./ivideo-service"
// import { VastMeta } from "models/dsp/video.dto"

import { VastMeta, Video } from "models/dsp/video.dto";
import { IVideoService } from "./ivideo-service";


export class MockVideoService implements IVideoService {

  videos: Video[];

  constructor() {
    this.videos = [];
    for (let i = 0; i < 200; i++) {
      this.videos.push({
        id: i,
        name: `Video ${i}`,
        interval: [new Date(), new Date()],
        vastTagGeneratorType: "upload"
      })
    }
  }

  async getById(id: number): Promise<Video> {
    return this.videos.find(v => v.id === id)!;
  }

  async create(video: Video): Promise<Video> {
    this.videos.push(video);
    return video;
  }

  async update(video: Video): Promise<Video> {
    const index = this.videos.findIndex(v => v.id === video.id);
    this.videos[index] = video;
    return video;
  }
}
