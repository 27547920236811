import { ConnectedService } from "services/connected-service";
import { Placement, PlacementsAttributes } from "models/dsp/placements.dto";
import { ConnectedServiceResult } from "services/connected-service-result";
import moment, { Moment } from "moment";
import { IBulkEditService } from "./ibulk-edit-service";

type BulkEdit = ConnectedServiceResult<any>;

//type
//BulkEditPayload

export class ConnectedBulkEditService extends ConnectedService implements IBulkEditService {
  async bulkEdit(payload: any): Promise<Placement> {
    let bulkEdit = await this._post<BulkEdit>('dsp/bulk-edit', payload);
    if (bulkEdit.success) {
      return bulkEdit.data!;
    } else {
      throw new Error(bulkEdit.message);
    }
  }
}