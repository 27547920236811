import { IListService } from './ilist-service'
import { ConnectedService } from 'services/connected-service'
import { ConnectedServiceResult } from 'services/connected-service-result'
import moment from 'moment'

import { List } from 'models/dsp/list.dto'
import { APIQuery } from 'models/query/query.dto'

interface ListResults extends ConnectedServiceResult<List[]> {}

export class ConnectedListService extends ConnectedService implements IListService {
  async queryLists(_analyticFilter: any[], query: APIQuery): Promise<List[]> {
    const response = await this._post<ListResults>('dsp/lists/query', query)
    if (response.success === true && response.data !== undefined) {
      return this.mapList(response.data)
    }
    throw new Error(response.message)
  }

  private mapList(lists:any[]):any[]{
    let tableData: any[]=[]
    lists.forEach(list=>{
      tableData.push({
        ...list,
        list_type: list.listType
      })
    })

    return tableData

  }
}
