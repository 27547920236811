import { ServiceContext, ServiceContextServices } from "service-context"

interface ServiceContextArguments extends ServiceContextServices {
  children: any
};

export default function ServiceContextProvider(
  {
    children,
    accountService,
    advertiserService,
    campaignService,
    sourceService,
    strategyService,
    creativeService,
    tagService,
    ruleService,
    bannerService,
    uploadService,
    videoService,
    targetingService,
    placementService,
    nativeService,
    listService,
    bulkEditService,
    analyticService,
    userMetaService,
    publisherService
  }: ServiceContextArguments
) {
  return (
    <ServiceContext.Provider value={{
      accountService,
      advertiserService,
      campaignService,
      sourceService,
      strategyService,
      creativeService,
      tagService,
      ruleService,
      bannerService,
      uploadService,
      videoService,
      targetingService,
      placementService,
      nativeService,
      listService,
      bulkEditService,
      analyticService,
      userMetaService,
      publisherService
    }}>
      {children}
    </ServiceContext.Provider>
  );
}
