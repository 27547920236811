import { ConnectedService } from "services/connected-service";
import { IRuleService, Rule } from "./irule-service";
import { RuleDto } from "models/dsp/rule.dto";
import { ConnectedServiceResult } from "services/connected-service-result";
import { APIQuery } from "models/query/query.dto";

interface RuleResults extends ConnectedServiceResult<RuleDto[]> { }

export class ConnectedRuleService extends ConnectedService implements IRuleService {

  constructor(url: string) {
    super(url)
  }

  async getRules(): Promise<Rule[]> {
    const response = await this._get<RuleResults>('dsp/rules');
    if (response.success === true && response.data !== undefined) {
      return response.data.map(ruleDto => ({
        id: ruleDto.id!,
        name: ruleDto.name!,
        sourceId: ruleDto.sourceId!,
        accountId: ruleDto.accountId!
      }));
    }
    throw new Error(response.message);
  }

  async getRulesBySourceId(sourceId: string): Promise<Rule[]> {
    let ruleQuery = {
      "type": "rule",
      "filters": [{
        "sourceId": sourceId
      }],
    }
    const response = await this._post<RuleResults>('dsp/rules/query', ruleQuery);
    if (response.success === true && response.data !== undefined) {
      return response.data.map(ruleDto => ({
        id: ruleDto.id!,
        name: ruleDto.name!,
        sourceId: ruleDto.sourceId!,
        accountId: ruleDto.accountId!
      }));
    }
    throw new Error(response.message);
  }

  async queryRules(_analyticFilter: any[], query: APIQuery): Promise<any[]> {
    const response = await this._post<RuleResults>('dsp/rules/query', query)
    if (response.success === true && response.data !== undefined) {
      return response.data
    }
    throw new Error(response.message)
  }
}